import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';

// a custom hook that uses the useUser hook from Clerk to manage the user query in dev tool
export function useUserQuery() {
  const { user, isLoaded, isSignedIn } = useUser();

  return useQuery({
    queryKey: ['user', user?.id, user?.publicMetadata, isLoaded, isSignedIn],
    queryFn: async () => { return user; },
  });
}
