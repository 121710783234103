/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BaseWriteConfigObject
 */
export interface BaseWriteConfigObject {
    /**
     * The name of the object to write to.
     * @type {string}
     * @memberof BaseWriteConfigObject
     */
    objectName?: string;
}

/**
 * Check if a given object implements the BaseWriteConfigObject interface.
 */
export function instanceOfBaseWriteConfigObject(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BaseWriteConfigObjectFromJSON(json: any): BaseWriteConfigObject {
    return BaseWriteConfigObjectFromJSONTyped(json, false);
}

export function BaseWriteConfigObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseWriteConfigObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectName': !exists(json, 'objectName') ? undefined : json['objectName'],
    };
}

export function BaseWriteConfigObjectToJSON(value?: BaseWriteConfigObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectName': value.objectName,
    };
}

