/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OptionalFieldsAutoOption = {
    All: 'all'
} as const;
export type OptionalFieldsAutoOption = typeof OptionalFieldsAutoOption[keyof typeof OptionalFieldsAutoOption];


export function OptionalFieldsAutoOptionFromJSON(json: any): OptionalFieldsAutoOption {
    return OptionalFieldsAutoOptionFromJSONTyped(json, false);
}

export function OptionalFieldsAutoOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalFieldsAutoOption {
    return json as OptionalFieldsAutoOption;
}

export function OptionalFieldsAutoOptionToJSON(value?: OptionalFieldsAutoOption | null): any {
    return value as any;
}

