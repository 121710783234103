import { FC } from 'react';

import { TableComponent } from 'components/TableComponent';

type TableRowProps = {
  children: React.ReactElement;
};
export const TableRow: FC<TableRowProps> = ({
  children,
}) => {
  return (
    <TableComponent.TableRow>
      <TableComponent.TableCell>
        {children}
      </TableComponent.TableCell>
    </TableComponent.TableRow>
  );
};
type TableContentProps = {
  children: React.ReactNode;
};
// Table component used in SelectProjectPage
export const SelectProjectTableContent = ({
  children,
}: TableContentProps) => {
  return (
    <TableComponent.Table>
      <TableComponent.TableHeader>
        <TableComponent.TableRow>
          <TableComponent.TableHeaderCell key="project">
            Project
          </TableComponent.TableHeaderCell>
        </TableComponent.TableRow>
      </TableComponent.TableHeader>
      <TableComponent.TableBody style={{ maxHeight: '20rem', overflowY: 'scroll' }}>
        {children}
      </TableComponent.TableBody>
    </TableComponent.Table>
  );
};
