import { ORG_OWNER_ROLE, useGetMyInfoQuery } from 'src/hooks/useGetMyInfoQuery';
import { SidebarLayout } from 'src/layout/SidebarLayout/SidebarLayout';

import { OrgNavSection } from './section/OrgNavSection';
import { ProjectNavSubsection } from './section/project/ProjectNavSubsection';

export const SidebarMain = () => {
  const { data: myInfo } = useGetMyInfoQuery();
  const isOrgOwner = myInfo?.orgRole?.role === ORG_OWNER_ROLE;

  return (
    <SidebarLayout.SidebarMain>
      <ProjectNavSubsection />
      {/* only show org tab if user is org owner */}
      {isOrgOwner && <OrgNavSection />}
    </SidebarLayout.SidebarMain>
  );
};
