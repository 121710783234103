import { SidebarLayout } from 'src/layout/SidebarLayout/SidebarLayout';

import { NavLink } from 'components/Nav';

import classes from './projectNavSubsection.module.css';

const navLinkSectionClassName = ({ isActive }: { isActive: boolean; }) => {
  return `${classes.sidebarSectionLink} ${isActive
    ? classes.sidebarSectionLinkActive : ''}`;
};

// hover state section item with no nav list (non-generalized)
export const ProjectNavSubsection = () => (
  <NavLink to="/home/projects" className={navLinkSectionClassName}>
    <div className={classes.sidebarSection}>
      <div className={classes.iconContainer}>
        {/* projects-logo.svg - need to support importing svg directly to support css changes */}
        <svg
          className={classes.icon}
          width="14"
          height="12"
          viewBox="0 0 14 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            // eslint-disable-next-line max-len
            d="M3.66667 3.33366L1 6.00033L3.66667 8.66699M10.3333 3.33366L13 6.00033L10.3333 8.66699M8.33333 0.666992L5.66667 11.3337"
            stroke="black"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <SidebarLayout.SidebarMainLayout.Title title="Projects" />
    </div>
  </NavLink>
);
