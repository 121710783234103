export enum ColorEnum {
  RED = 'RED',
  GREEN = 'GREEN',
  BLACK = 'BLACK',
}

export enum StatusDetailEnum {
  success = 'success',
  failure = 'failure',
}

export const greenStatuses = new Set([
  StatusDetailEnum.success,
]);

export const redStatuses = new Set([
  StatusDetailEnum.failure,
]);

export enum StatusEnum {
  UNHEALTHY = 'UNHEALTHY',
  HEALTHY = 'HEALTHY',
  UNKNOWN = 'UNKNOWN',
}

// A mapping from the status strings returned by the API and the status enum.
export const statusEnumLookup: { [key: string]: StatusEnum } = {
  healthy: StatusEnum.HEALTHY,
  unhealthy: StatusEnum.UNHEALTHY,
};

export const colorStatusMap = {
  [StatusEnum.HEALTHY]: ColorEnum.GREEN,
  [StatusEnum.UNHEALTHY]: ColorEnum.RED,
  [StatusEnum.UNKNOWN]: ColorEnum.BLACK,
};
