/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * If included, creating this integration will also create a new revision of the integration. For LatestRevision, one of sourceZipUrl or sourceYaml is required.
 * @export
 * @interface CreateIntegrationRequestLatestRevision
 */
export interface CreateIntegrationRequestLatestRevision {
    /**
     * URL of where a zip of the source files can be downloaded (e.g. Google Cloud Storage URL).
     * @type {string}
     * @memberof CreateIntegrationRequestLatestRevision
     */
    sourceZipUrl?: string;
    /**
     * A YAML string that defines the integration.
     * @type {string}
     * @memberof CreateIntegrationRequestLatestRevision
     */
    sourceYaml?: string;
}

/**
 * Check if a given object implements the CreateIntegrationRequestLatestRevision interface.
 */
export function instanceOfCreateIntegrationRequestLatestRevision(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateIntegrationRequestLatestRevisionFromJSON(json: any): CreateIntegrationRequestLatestRevision {
    return CreateIntegrationRequestLatestRevisionFromJSONTyped(json, false);
}

export function CreateIntegrationRequestLatestRevisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateIntegrationRequestLatestRevision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceZipUrl': !exists(json, 'sourceZipUrl') ? undefined : json['sourceZipUrl'],
        'sourceYaml': !exists(json, 'sourceYaml') ? undefined : json['sourceYaml'],
    };
}

export function CreateIntegrationRequestLatestRevisionToJSON(value?: CreateIntegrationRequestLatestRevision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourceZipUrl': value.sourceZipUrl,
        'sourceYaml': value.sourceYaml,
    };
}

