/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkWriteSupport
 */
export interface BulkWriteSupport {
    /**
     * 
     * @type {boolean}
     * @memberof BulkWriteSupport
     */
    insert: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BulkWriteSupport
     */
    update: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BulkWriteSupport
     */
    upsert: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BulkWriteSupport
     */
    _delete: boolean;
}

/**
 * Check if a given object implements the BulkWriteSupport interface.
 */
export function instanceOfBulkWriteSupport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "insert" in value;
    isInstance = isInstance && "update" in value;
    isInstance = isInstance && "upsert" in value;
    isInstance = isInstance && "_delete" in value;

    return isInstance;
}

export function BulkWriteSupportFromJSON(json: any): BulkWriteSupport {
    return BulkWriteSupportFromJSONTyped(json, false);
}

export function BulkWriteSupportFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkWriteSupport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'insert': json['insert'],
        'update': json['update'],
        'upsert': json['upsert'],
        '_delete': json['delete'],
    };
}

export function BulkWriteSupportToJSON(value?: BulkWriteSupport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'insert': value.insert,
        'update': value.update,
        'upsert': value.upsert,
        'delete': value._delete,
    };
}

