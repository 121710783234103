/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OauthConnectRequest
 */
export interface OauthConnectRequest {
    /**
     * The identifier for the provider workspace
     * @type {string}
     * @memberof OauthConnectRequest
     */
    providerWorkspaceRef?: string;
    /**
     * The Ampersand project ID.
     * @type {string}
     * @memberof OauthConnectRequest
     */
    projectId: string;
    /**
     * The ID that your app uses to identify the group of users for this Connection, this can be an Org ID, workspace ID, etc.
     * @type {string}
     * @memberof OauthConnectRequest
     */
    groupRef: string;
    /**
     * The display name for the group. Defaults to groupRef if not provided.
     * @type {string}
     * @memberof OauthConnectRequest
     */
    groupName?: string;
    /**
     * The ID that your app uses to identify the user whose SaaS credential will be used for this OAuth flow.
     * @type {string}
     * @memberof OauthConnectRequest
     */
    consumerRef: string;
    /**
     * The display name for the consumer. Defaults to consumerRef if not provided.
     * @type {string}
     * @memberof OauthConnectRequest
     */
    consumerName?: string;
    /**
     * ID of the provider app (e.g. Salesforce connected app), returned from a CreateProviderApp call. If omitted, the default provider app that was set up on the Ampersand Dashboard is assumed.
     * @type {string}
     * @memberof OauthConnectRequest
     */
    providerAppId?: string;
    /**
     * The provider that this app connects to.
     * @type {string}
     * @memberof OauthConnectRequest
     */
    provider: string;
}

/**
 * Check if a given object implements the OauthConnectRequest interface.
 */
export function instanceOfOauthConnectRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "groupRef" in value;
    isInstance = isInstance && "consumerRef" in value;
    isInstance = isInstance && "provider" in value;

    return isInstance;
}

export function OauthConnectRequestFromJSON(json: any): OauthConnectRequest {
    return OauthConnectRequestFromJSONTyped(json, false);
}

export function OauthConnectRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OauthConnectRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'providerWorkspaceRef': !exists(json, 'providerWorkspaceRef') ? undefined : json['providerWorkspaceRef'],
        'projectId': json['projectId'],
        'groupRef': json['groupRef'],
        'groupName': !exists(json, 'groupName') ? undefined : json['groupName'],
        'consumerRef': json['consumerRef'],
        'consumerName': !exists(json, 'consumerName') ? undefined : json['consumerName'],
        'providerAppId': !exists(json, 'providerAppId') ? undefined : json['providerAppId'],
        'provider': json['provider'],
    };
}

export function OauthConnectRequestToJSON(value?: OauthConnectRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'providerWorkspaceRef': value.providerWorkspaceRef,
        'projectId': value.projectId,
        'groupRef': value.groupRef,
        'groupName': value.groupName,
        'consumerRef': value.consumerRef,
        'consumerName': value.consumerName,
        'providerAppId': value.providerAppId,
        'provider': value.provider,
    };
}

