import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
  useNavigate as routerUseNavigate,
  useSearchParams,
} from 'react-router-dom';
import { urlPathWithSearchParams } from 'src/util';

export interface NavLinkProps extends RouterNavLinkProps {
  to: string;
}

export function useNavigate() {
  const nav = routerUseNavigate();
  const [searchParams] = useSearchParams();

  return (...args: any[]) => {
    nav(urlPathWithSearchParams(args[0], searchParams.toString()), ...args.slice(1));
  };
}

export const NavLink = (props: NavLinkProps) => {
  const [searchParams] = useSearchParams();
  const { to, children } = props;

  return (
    <RouterNavLink {...props} to={urlPathWithSearchParams(to, searchParams.toString())}>
      {children}
    </RouterNavLink>
  );
};

export interface LinkProps extends RouterLinkProps {
  to: string;
}

export const Link = (props: LinkProps) => {
  const [searchParams] = useSearchParams();
  const { to, children } = props;

  return (
    <RouterLink {...props} to={urlPathWithSearchParams(to as string, searchParams.toString())}>
      {children}
    </RouterLink>
  );
};
