/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Builder
 */
export interface Builder {
    /**
     * The builder ID.
     * @type {string}
     * @memberof Builder
     */
    id: string;
    /**
     * The identity provider
     * @type {string}
     * @memberof Builder
     */
    idpProvider: string;
    /**
     * ID used by the identity provider
     * @type {string}
     * @memberof Builder
     */
    idpRef: string;
    /**
     * The time that the builder joined Ampersand.
     * @type {Date}
     * @memberof Builder
     */
    createTime: Date;
    /**
     * 
     * @type {string}
     * @memberof Builder
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Builder
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Builder
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof Builder
     */
    primaryEmail?: string;
}

/**
 * Check if a given object implements the Builder interface.
 */
export function instanceOfBuilder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "idpProvider" in value;
    isInstance = isInstance && "idpRef" in value;
    isInstance = isInstance && "createTime" in value;

    return isInstance;
}

export function BuilderFromJSON(json: any): Builder {
    return BuilderFromJSONTyped(json, false);
}

export function BuilderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Builder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'idpProvider': json['idpProvider'],
        'idpRef': json['idpRef'],
        'createTime': (new Date(json['createTime'])),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'primaryEmail': !exists(json, 'primaryEmail') ? undefined : json['primaryEmail'],
    };
}

export function BuilderToJSON(value?: Builder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idpProvider': value.idpProvider,
        'idpRef': value.idpRef,
        'createTime': (value.createTime.toISOString()),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'fullName': value.fullName,
        'primaryEmail': value.primaryEmail,
    };
}

