import SettingsIcon from 'assets/settings-logo.svg';
import { SidebarLayout } from 'src/layout/SidebarLayout/SidebarLayout';

export const OrgNavSection = () => (
  <SidebarLayout.SidebarMainLayout.SidebarSection
    title="Organization"
    alt="organization"
    iconSrc={SettingsIcon}
  >
    <SidebarLayout.SidebarMainLayout.ListNavLink to="/home/org-settings" label="General" />
    <SidebarLayout.SidebarMainLayout.ListNavLink to="/home/org-users" label="Users" />
  </SidebarLayout.SidebarMainLayout.SidebarSection>
);
