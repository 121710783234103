/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Configuration for API key in header. Must be provided if type is in-header.
 * @export
 * @interface ApiKeyOptsHeader
 */
export interface ApiKeyOptsHeader {
    /**
     * The name of the header to be used for the API key.
     * @type {string}
     * @memberof ApiKeyOptsHeader
     */
    name: string;
    /**
     * The prefix to be added to the API key value when it is sent in the header.
     * @type {string}
     * @memberof ApiKeyOptsHeader
     */
    valuePrefix?: string;
}

/**
 * Check if a given object implements the ApiKeyOptsHeader interface.
 */
export function instanceOfApiKeyOptsHeader(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function ApiKeyOptsHeaderFromJSON(json: any): ApiKeyOptsHeader {
    return ApiKeyOptsHeaderFromJSONTyped(json, false);
}

export function ApiKeyOptsHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiKeyOptsHeader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'valuePrefix': !exists(json, 'valuePrefix') ? undefined : json['valuePrefix'],
    };
}

export function ApiKeyOptsHeaderToJSON(value?: ApiKeyOptsHeader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'valuePrefix': value.valuePrefix,
    };
}

