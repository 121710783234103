import { Outlet } from 'react-router-dom';

import { ProtectedPage } from '../ProtectedPage/ProtectedPage';

import { OnboardingTemplate } from '.';

export const ProtectedOnboardingLayout = (
  <ProtectedPage>
    <OnboardingTemplate>
      <Outlet />
    </OnboardingTemplate>
  </ProtectedPage>
);
