import { Link, LinkProps } from 'components/Nav';

import classes from './linkbutton.module.css';

interface LinkButtonProps extends LinkProps {
  label: string;
}

/**
 * a link that is styled like a button in primary colors
 * @param label
 * @returns
 */
const LinkButtonGeneral: React.FC<LinkButtonProps> = ({ label, className, ...props }) => (
  <Link {...props} className={className || classes.linkButton}>
    {label}
  </Link>
);

/**
 * A link that is styled like a button in secondary colors
 * @param label
 * @returns
 */
const LinkButtonSecondary: React.FC<LinkButtonProps> = ({ className, ...props }) => (
  <LinkButtonGeneral className={className || classes.linkButtonSecondary} {...props} />
);

export const LinkButton = {
  Primary: LinkButtonGeneral,
  Secondary: LinkButtonSecondary,
};
