import { FC } from 'react';
import { useClerk } from '@clerk/clerk-react';
import ChevronDownIcon from 'assets/chevron-down.svg';
import IntegrationIcon from 'assets/integration-logo.svg';
import LogoutIcon from 'assets/logout-icon.svg';
import { useUser } from 'auth/index';
import { posthog } from 'posthog-js';
import { ORG_OWNER_ROLE, useGetMyInfoQuery } from 'src/hooks/useGetMyInfoQuery';

import { useNavigate } from 'components/Nav';

import { SidebarDropdownButton } from './Button';
import { OrganizationDropdownSection } from './OrganizationDropdownSection';
import { SwitchProjectSection } from './SwitchProjectSection';

import classes from './style.module.css';

type DropdownContentProps = {
  inProject?: boolean;
  setOpen: (open: boolean) => void;
  setUserProfileOpen: (open: boolean) => void;
};

export const DropdownContent: FC<DropdownContentProps> = ({
  inProject,
  setOpen,
  setUserProfileOpen,
}) => {
  const navigate = useNavigate();
  const { signOut } = useClerk();
  const { user } = useUser();

  const { data: builderInfo } = useGetMyInfoQuery();
  const isOrgOwner = builderInfo?.orgRole?.role === ORG_OWNER_ROLE;

  return (
    <div className={classes.dropdownContent}>
      <p className={classes.dropdownContentHeading}>Project</p>
      <button type="button" className={classes.dropdownContentButton}>
        <img src={IntegrationIcon} alt="switch" />
        <span>{inProject ? 'Switch projects' : 'Go to project'}</span>
        <img src={ChevronDownIcon} alt="right arrow" className={classes.rightArrow} />
      </button>

      {/* Switch Project Section */}
      <SwitchProjectSection setOpen={setOpen} builderInfo={builderInfo} />
      <div className={classes.separator} />

      {/* Organization Section in project view only */}
      {isOrgOwner && <OrganizationDropdownSection />}

      {/* User Section */}
      <p className={classes.dropdownContentHeading}>User</p>
      <button
        type="button"
        className={classes.dropdownContentButton}
        onClick={() => {
          setOpen(false);
          setUserProfileOpen(true);
        }}
      >
        <img src={user?.imageUrl} alt="profile" className={classes.dropdownContentProfilePicture} />
        <div className={classes.dropdownContentProfileStack}>
          <p>{user?.fullName}</p>
          <p className={classes.dropdownContentEmail}>{user?.primaryEmailAddress?.emailAddress}</p>
        </div>
      </button>
      <SidebarDropdownButton
        icon={<img src={LogoutIcon} alt="log out" className={classes.dropdownContentSignoutIcon} />}
        title="Sign out"
        onClick={() => {
          posthog.reset();
          signOut();
          navigate('/sign-in');
        }}
      />
    </div>
  );
};
