import { useState } from 'react';
import { Link } from 'react-router-dom';

import classes from './style.module.css';
// eslint-disable-next-line max-len
const AmpersandLogoURL = 'https://res.cloudinary.com/dycvts6vp/image/upload/fl_preserve_transparency/v1723671980/ampersand-logo-black_fwzpfw.svg?_s=public-apps';
/**
  * Ampersand Logo Component returns cdn logo if present else text
  * @returns
  */
export const AmpersandLogo = () => {
  const [imgError, setImgError] = useState(false);
  const handleImageError = () => setImgError(true);

  return (
    <Link to="/">
      {imgError ? <span className={classes.ampersandLogo}>AMPERSAND</span> : (
        <img src={AmpersandLogoURL} alt="logo" onError={handleImageError} />
      )}
    </Link>
  );
};
