// disable fast refresh for this file to use Compound Components pattern
// must hard refresh to see changes

import cn from 'classnames';

import { SectionListNavLink } from './SectionListNavLink/SectionListNavLink';
import { SectionTitle } from './SectionTitle/SectionTitle';
import { SidebarSection } from './SidebarSection/SidebarSection';

import classes from './sidebarLayout.module.css';

type LayoutProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

const LayoutContainer = ({ children, style, className }: LayoutProps) => (
  <div
    className={className ? cn(classes.layoutContainer, className) : classes.layoutContainer}
    style={style}
  >
    {children}
  </div>
);

const Sidebar = ({ children, style, className }: LayoutProps) => (
  <div
    className={className ? cn(classes.sidebar, className) : classes.sidebar}
    style={style}
  >
    {children}
  </div>
);

const MainPage = ({ children, style, className }: LayoutProps) => (
  <div
    className={className ? cn(classes.mainPage, className) : classes.mainPage}
    style={style}
  >
    {children}
  </div>
);

const SidebarHeader = ({ children, style, className }: LayoutProps) => (
  <div
    className={className ? cn(classes.sidebarHeader, className) : classes.sidebarHeader}
    style={style}
  >
    {children}
  </div>
);

const SidebarMain = ({ children, style, className }: LayoutProps) => (
  <div
    className={className ? cn(classes.sidebarMain, className) : classes.sidebarMain}
    style={style}
  >
    {children}
  </div>
);

// sidebar main subcomponents
const SidebarMainLayout = {
  Title: SectionTitle,
  ListNavLink: SectionListNavLink,
  SidebarSection,
};

export const SidebarLayout = {
  LayoutContainer,
  MainPage,
  Sidebar,
  SidebarHeader,
  SidebarMain,
  // sidebar main subcomponents
  SidebarMainLayout,
};
