import { ButtonHTMLAttributes, FC } from 'react';

import classes from './style.module.css';

type SidebarDropdownButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon: string | JSX.Element,
  title: string,
};

export const SidebarDropdownButton: FC<SidebarDropdownButtonProps> = ({
  icon,
  title,
  ...props
}) => (
  <button type="button" className={classes.dropdownContentButton} {...props}>
    {typeof icon === 'string'
      ? (<img src={icon} alt={title} className={classes.dropdownContentButtonIcon} />)
      : (icon)}
    <span>{title}</span>
  </button>
);
