import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import { appRoutes } from './routes';

import './index.css';

const router = createBrowserRouter(appRoutes);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
/**
 * placeholder for the dynamic load error page
 * https://github.com/vitejs/vite/issues/11804
 */
const swapDynamicLoadErrorPage = () => {
  if (document) {
    const rootElement = document.getElementById('root') as HTMLDivElement;
    if (rootElement) {
      rootElement.innerHTML = `<div class="redirect_screen">
      <h4><strong>New update available.</strong></h4>
      <p>Reloading in progress. Thanks for your patience.</p>
    </div>`;
    }
  }
};

// Listen for preload errors, and reload the page if one occurs
// common use case will when user is logged in and a deployment changes the hash of the file
window.addEventListener('vite:preloadError', () => {
  console.log('An error occurred while loading the page. Refreshing the page.');
  window.location.reload(); // for example, refresh the page
  swapDynamicLoadErrorPage();
});
