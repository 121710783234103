import { Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { PostHogProvider } from 'components/Posthog';
import { Toast } from 'components/Toast';

import { AuthLayout } from './auth/AuthLayout';
import { defaultOptions } from './hooks/query';

import './App.css';

const queryClient = new QueryClient(
  defaultOptions,
);

/**
 * If user is signed in, renders the main page with sidebar
 * If user is not, redirects to sign in page.
 *
 * @returns
 */
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="app">
        <AuthLayout>
          <PostHogProvider>
            <Outlet />
          </PostHogProvider>
        </AuthLayout>
        <Toast.ToastContainer />
      </div>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
}

export default App;
