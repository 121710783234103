/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegrationFieldMapping
 */
export interface IntegrationFieldMapping {
    /**
     * 
     * @type {string}
     * @memberof IntegrationFieldMapping
     */
    mapToName: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationFieldMapping
     */
    mapToDisplayName?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationFieldMapping
     */
    _default?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationFieldMapping
     */
    prompt?: string;
}

/**
 * Check if a given object implements the IntegrationFieldMapping interface.
 */
export function instanceOfIntegrationFieldMapping(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "mapToName" in value;

    return isInstance;
}

export function IntegrationFieldMappingFromJSON(json: any): IntegrationFieldMapping {
    return IntegrationFieldMappingFromJSONTyped(json, false);
}

export function IntegrationFieldMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationFieldMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mapToName': json['mapToName'],
        'mapToDisplayName': !exists(json, 'mapToDisplayName') ? undefined : json['mapToDisplayName'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
        'prompt': !exists(json, 'prompt') ? undefined : json['prompt'],
    };
}

export function IntegrationFieldMappingToJSON(value?: IntegrationFieldMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mapToName': value.mapToName,
        'mapToDisplayName': value.mapToDisplayName,
        'default': value._default,
        'prompt': value.prompt,
    };
}

