/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HydratedIntegrationObject } from './HydratedIntegrationObject';
import {
    HydratedIntegrationObjectFromJSON,
    HydratedIntegrationObjectFromJSONTyped,
    HydratedIntegrationObjectToJSON,
} from './HydratedIntegrationObject';

/**
 * 
 * @export
 * @interface HydratedIntegrationRead
 */
export interface HydratedIntegrationRead {
    /**
     * 
     * @type {Array<HydratedIntegrationObject>}
     * @memberof HydratedIntegrationRead
     */
    objects?: Array<HydratedIntegrationObject>;
}

/**
 * Check if a given object implements the HydratedIntegrationRead interface.
 */
export function instanceOfHydratedIntegrationRead(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HydratedIntegrationReadFromJSON(json: any): HydratedIntegrationRead {
    return HydratedIntegrationReadFromJSONTyped(json, false);
}

export function HydratedIntegrationReadFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydratedIntegrationRead {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objects': !exists(json, 'objects') ? undefined : ((json['objects'] as Array<any>).map(HydratedIntegrationObjectFromJSON)),
    };
}

export function HydratedIntegrationReadToJSON(value?: HydratedIntegrationRead | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objects': value.objects === undefined ? undefined : ((value.objects as Array<any>).map(HydratedIntegrationObjectToJSON)),
    };
}

