import { FC, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { PostHogProvider as Posthog, usePostHog } from 'posthog-js/react';
import { useGetMyInfoQuery } from 'src/hooks/useGetMyInfoQuery';

type PosthogProviderProps = {
  children: React.ReactNode;
};

const POSTHOG_API_KEY = import.meta.env.VITE_POSTHOG_API_KEY;
const posthogApiHost = 'https://us.i.posthog.com';

const excludeSet = new Set(['__clerk_handshake']);

export const PostHogProvider: FC<PosthogProviderProps> = ({ children }) => {
  const { projectId } = useParams();
  const posthog = usePostHog();
  const [searchParams] = useSearchParams();

  const memoizedSearchParams = useMemo(() => {
    const srchParams: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      if (excludeSet.has(key)) return;
      srchParams[key] = value;
    });

    return srchParams;
  }, [searchParams]);
  // reset posthog identity when projectId and builderInfo changes
  const { data: builderInfo } = useGetMyInfoQuery();
  useEffect(() => {
    posthog.reset();
    const identity = { // identify the user with builder id
      idpProvider: builderInfo?.builder?.idpProvider,
      idpRef: builderInfo?.builder?.idpRef,
      primaryEmailAddress: builderInfo?.builder?.primaryEmail,
      orgId: builderInfo?.orgRole?.org?.id,
      builderId: builderInfo?.builder?.id,
      authType: 'user',
      searchParams: memoizedSearchParams,
    };

    posthog.identify(projectId, identity);
  }, [projectId, builderInfo, posthog, memoizedSearchParams]);

  return (
    <Posthog
      apiKey={POSTHOG_API_KEY}
      options={{
        api_host: posthogApiHost,
        person_profiles: 'identified_only',
      }}
    >
      {children}
    </Posthog>
  );
};
