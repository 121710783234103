/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateDestinationRequestDestinationMetadata } from './UpdateDestinationRequestDestinationMetadata';
import {
    UpdateDestinationRequestDestinationMetadataFromJSON,
    UpdateDestinationRequestDestinationMetadataFromJSONTyped,
    UpdateDestinationRequestDestinationMetadataToJSON,
} from './UpdateDestinationRequestDestinationMetadata';

/**
 * 
 * @export
 * @interface Destination
 */
export interface Destination {
    /**
     * The destination ID.
     * @type {string}
     * @memberof Destination
     */
    id: string;
    /**
     * User-defined name for the destination
     * @type {string}
     * @memberof Destination
     */
    name: string;
    /**
     * The type of the destination
     * @type {string}
     * @memberof Destination
     */
    type: string;
    /**
     * 
     * @type {UpdateDestinationRequestDestinationMetadata}
     * @memberof Destination
     */
    metadata: UpdateDestinationRequestDestinationMetadata;
    /**
     * The time the destination was created.
     * @type {Date}
     * @memberof Destination
     */
    createTime: Date;
    /**
     * The time the destination was updated.
     * @type {Date}
     * @memberof Destination
     */
    updateTime?: Date;
}

/**
 * Check if a given object implements the Destination interface.
 */
export function instanceOfDestination(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "createTime" in value;

    return isInstance;
}

export function DestinationFromJSON(json: any): Destination {
    return DestinationFromJSONTyped(json, false);
}

export function DestinationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Destination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
        'metadata': UpdateDestinationRequestDestinationMetadataFromJSON(json['metadata']),
        'createTime': (new Date(json['createTime'])),
        'updateTime': !exists(json, 'updateTime') ? undefined : (new Date(json['updateTime'])),
    };
}

export function DestinationToJSON(value?: Destination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'metadata': UpdateDestinationRequestDestinationMetadataToJSON(value.metadata),
        'createTime': (value.createTime.toISOString()),
        'updateTime': value.updateTime === undefined ? undefined : (value.updateTime.toISOString()),
    };
}

