import SettingsIcon from 'assets/settings-logo.svg';

import { useNavigate } from 'components/Nav';

import { SidebarDropdownButton } from './Button';

import classes from './style.module.css';

export const OrganizationDropdownSection = () => {
  const navigate = useNavigate();
  return (
    <>
      <p className={classes.dropdownContentHeading}>Organization</p>
      <SidebarDropdownButton
        icon={SettingsIcon}
        title="Organization settings"
        onClick={() => navigate('/home/org-settings')}
      />
      <div className={classes.separator} />
    </>
  );
};
