import classNames from 'classnames';

import classes from './sectionTitle.module.css';

type SectionTitleProps = {
  title: string;
  className?: string;
};

export const SectionTitle = ({ title, className }: SectionTitleProps) => (
  <h2 className={className ? classNames(classes.navTitle, className) : classes.navTitle}>
    {title}
  </h2>
);
