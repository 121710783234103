import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAPI } from 'data/api';
import { MutationPayload } from 'src/hooks/query';

export function useAcceptInviteMutation() {
  const getAPI = useAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['acceptInvite'],
    mutationFn: async (payload: MutationPayload<string /* invitedEmail */>) => {
      const api = await getAPI();
      return api.userApi.acceptInvite({ acceptInviteRequest: { invitedEmail: payload.data } });
    },
    onSuccess: () => {
      // Invalidate all queries that depend on user, invites, and builders table
      queryClient.invalidateQueries({ queryKey: ['myInfo'] });
    },
    retryDelay: 2000,
    retry: 3,
  });
}
