import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import {
  useUser,
} from 'auth/index';

type ProtectedPageProps = {
  children: React.ReactNode;
};

/**
 * If user is signed in, renders the main page with sidebar
 * If user is not, redirects to sign in page.
 *
 * @returns
 */
export const ProtectedPage: FC<ProtectedPageProps> = ({ children }) => {
  const { isSignedIn, isLoaded } = useUser();
  if (!isLoaded) {
    return null;
  }
  if (isSignedIn) {
    return children;
  }
  return (<Navigate to="/sign-in" />);
};
