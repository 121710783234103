/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConfigContent } from './ConfigContent';
import {
    ConfigContentFromJSON,
    ConfigContentFromJSONTyped,
    ConfigContentToJSON,
} from './ConfigContent';

/**
 * The config of the installation.
 * @export
 * @interface ImportInstallationRequestConfig
 */
export interface ImportInstallationRequestConfig {
    /**
     * The ID of the integration revision that this config is based on. If omitted, the latest revision is assumed.
     * @type {string}
     * @memberof ImportInstallationRequestConfig
     */
    revisionId?: string;
    /**
     * 
     * @type {ConfigContent}
     * @memberof ImportInstallationRequestConfig
     */
    content: ConfigContent;
}

/**
 * Check if a given object implements the ImportInstallationRequestConfig interface.
 */
export function instanceOfImportInstallationRequestConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function ImportInstallationRequestConfigFromJSON(json: any): ImportInstallationRequestConfig {
    return ImportInstallationRequestConfigFromJSONTyped(json, false);
}

export function ImportInstallationRequestConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportInstallationRequestConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'revisionId': !exists(json, 'revisionId') ? undefined : json['revisionId'],
        'content': ConfigContentFromJSON(json['content']),
    };
}

export function ImportInstallationRequestConfigToJSON(value?: ImportInstallationRequestConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'revisionId': value.revisionId,
        'content': ConfigContentToJSON(value.content),
    };
}

