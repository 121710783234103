import { Outlet } from 'react-router-dom';
import { useIsOrgIdEqualProjectOrgId } from 'src/hooks/useIsOrgIdEqualProjectOrgId';

import { SidebarLayout } from '../SidebarLayout';

import { SidebarHeader } from './SidebarHeader/SidebarHeader';
import { SidebarMain } from './SidebarMain/SidebarMain';

// This is a sidebar layout for the organization settings page
const HomeSidebar = () => {
  // check if the org id is equal to the project org id
  useIsOrgIdEqualProjectOrgId(); // log error if org id is not equal to project org id

  return (
    <SidebarLayout.Sidebar>
      {/* Sidebar content goes here */}
      <SidebarHeader />
      <SidebarMain />
    </SidebarLayout.Sidebar>
  );
};

export const SidebarHomeLayout = () => (
  <SidebarLayout.LayoutContainer>
    <HomeSidebar />
    {/* Main page content goes here */}
    <SidebarLayout.MainPage>
      <Outlet />
    </SidebarLayout.MainPage>
  </SidebarLayout.LayoutContainer>
);
