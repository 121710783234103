import { Link, useLocation } from 'react-router-dom';
import ProjectBlockIcon from 'assets/project-logo-block.svg';
import { useGetMyInfoQuery } from 'src/hooks/useGetMyInfoQuery';

import { Loading } from 'components/Loading';

import { SelectProjectTableContent, TableRow } from './SelectProjectTableContent';

import classes from './selectProjectPage.module.css';

export const SelectProjectPage = () => {
  const location = useLocation();
  const { data: myInfo, isLoading } = useGetMyInfoQuery();
  const projectRoles = myInfo?.projectRoles ?? {};
  const projectIds = Object.keys(projectRoles);
  const from = location.state?.from;

  return (
    <div style={{
      width: '100%', padding: '4rem', height: 'calc(100vh - 7rem)', overflowY: 'scroll',
    }}
    >
      <h1 className={classes.title}>Select a project to proceed</h1>
      {isLoading && <Loading />}

      <SelectProjectTableContent>
        {!isLoading && projectIds.map((projectId) => {
          const project = projectRoles[projectId];
          const fromCopy = from.slice();
          const newToUrl = fromCopy.replace('_', projectId);
          return (
            <TableRow>
              <Link to={newToUrl} style={{ display: 'flex', gap: '1rem' }}>
                <img height="20px" src={ProjectBlockIcon} alt="project" />
                <span>{ project?.project.name }</span>
              </Link>
            </TableRow>
          );
        })}
      </SelectProjectTableContent>
    </div>
  );
};
