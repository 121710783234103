import { useEffect } from 'react';
import { useGetMyInfoQuery } from 'src/hooks/useGetMyInfoQuery';
import { createOrgPath, homePath } from 'src/routes/constants';

import { useNavigate } from 'components/Nav';

import { LoadingPage } from '../LoadingPage/LoadingPage';

import { useAcceptInviteMutation } from './queries/useAcceptInviteMutation';
import { useUserQuery } from './queries/useUserQuery';

/**
 * if user has been invited, accept invite and navigate to /home/projects
 * if user has not been invited, navigate to /onboarding/create-org
 * @returns {JSX.Element}
 */
export const SignUpRouter = () => {
  const navigate = useNavigate();
  const { data: user, isLoading, error } = useUserQuery();
  const { mutate } = useAcceptInviteMutation();
  const { data: myInfo, isLoading: isLoadingMyInfo } = useGetMyInfoQuery();
  const orgId = myInfo?.orgRole?.org.id;
  // invited email is stored in publicMetadata
  const invitedEmail = user?.publicMetadata?.invitedEmail as string || undefined;

  useEffect(() => {
    if (!isLoadingMyInfo && !isLoading) {
      if (orgId) {
        // if user has orgId, navigate to /home/projects
        console.log('User has orgId, navigating to /home/projects');
        navigate(homePath);
      } else if (user && myInfo) {
        // attempts to accept invite if user has been loaded
        // call accept invite mutation if user has invited email
        if (invitedEmail) {
          mutate({ data: invitedEmail, toastId: 'accept-invitation' }, {
            onSuccess: () => {
              // navigate to /home/projects after accepting invite
              console.log('Invite accepted, navigating to /home/projects');
              navigate(homePath);
            },
          });
        } else {
          // navigate to create org page if user has not been invited
          navigate(createOrgPath);
        }
      }
    }
  }, [invitedEmail, isLoading, isLoadingMyInfo, mutate, myInfo, navigate, orgId, user]);

  // placeholder for loading and error pages
  if (isLoadingMyInfo || isLoading) return <LoadingPage />;
  if (error) return <div>{`Error: ${error.message}`}</div>;
  if (!user) return <div>Invalid user</div>;
  if (user && invitedEmail) return <div>Accepting invite...</div>;
  return <div>Onboarding...</div>;
};
