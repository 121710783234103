/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDestinationRequestDestinationMetadata
 */
export interface UpdateDestinationRequestDestinationMetadata {
    /**
     * Webhook URL
     * @type {string}
     * @memberof UpdateDestinationRequestDestinationMetadata
     */
    url?: string;
    /**
     * Additional headers to add when Ampersand sends a webhook message
     * @type {{ [key: string]: string; }}
     * @memberof UpdateDestinationRequestDestinationMetadata
     */
    headers?: { [key: string]: string; } | null;
}

/**
 * Check if a given object implements the UpdateDestinationRequestDestinationMetadata interface.
 */
export function instanceOfUpdateDestinationRequestDestinationMetadata(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateDestinationRequestDestinationMetadataFromJSON(json: any): UpdateDestinationRequestDestinationMetadata {
    return UpdateDestinationRequestDestinationMetadataFromJSONTyped(json, false);
}

export function UpdateDestinationRequestDestinationMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDestinationRequestDestinationMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': !exists(json, 'url') ? undefined : json['url'],
        'headers': !exists(json, 'headers') ? undefined : json['headers'],
    };
}

export function UpdateDestinationRequestDestinationMetadataToJSON(value?: UpdateDestinationRequestDestinationMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'headers': value.headers,
    };
}

