import { FC } from 'react';
import { UserProfile } from '@clerk/clerk-react';

import classes from './style.module.css';

type UserProfileModalProps = {
  setUserProfileOpen: (open: boolean) => void;
};

export const UserProfileModal: FC<UserProfileModalProps> = ({
  setUserProfileOpen,
}) => {
  return (
    <div
      className={classes.background}
      onClick={(e) => {
        // If the user clicks outside the dialog, close it
        if (e.target === e.currentTarget) setUserProfileOpen(false);
      }}
      role="presentation"
    >
      <div className={classes.backgroundProfile}>
        <UserProfile routing="hash" />
      </div>
    </div>
  );
};
