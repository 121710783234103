import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SignIn } from 'auth/index';
import { OnboardingTemplate } from 'src/layout/OnboardingTemplate';
import {
  homePath, newUserPath, signUpPath,
} from 'src/routes/constants';

const pathWithSearchParams = (path: string, searchParamString: string) => {
  return path + (searchParamString ? `?${searchParamString}` : '');
};

export const SignInPage: FC = () => {
  const [searchParams] = useSearchParams();
  const searchParamString = searchParams.toString();

  return (
    <OnboardingTemplate>
      <SignIn
        signUpUrl={pathWithSearchParams(signUpPath, searchParamString)}
        forceRedirectUrl={pathWithSearchParams(homePath, searchParamString)}
        signUpForceRedirectUrl={pathWithSearchParams(newUserPath, searchParamString)}
      />
    </OnboardingTemplate>
  );
};
