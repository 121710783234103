import { useEffect, useState } from 'react';
import { LoadingPage } from 'src/views/LoadingPage/LoadingPage';

/**
 * only show the loading page after 300ms
*/
export const DelayedLoadingPage = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300);
    return () => clearTimeout(timeout);
  }, []);

  return show ? <LoadingPage /> : undefined;
};
