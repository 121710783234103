/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Org
 */
export interface Org {
    /**
     * The organization ID.
     * @type {string}
     * @memberof Org
     */
    id: string;
    /**
     * The organization label.
     * @type {string}
     * @memberof Org
     */
    label: string;
    /**
     * The ID of the Everyone team for the org.
     * @type {string}
     * @memberof Org
     */
    defaultTeamId: string;
    /**
     * The time the organization was created.
     * @type {Date}
     * @memberof Org
     */
    createTime: Date;
    /**
     * The time the organization was updated.
     * @type {Date}
     * @memberof Org
     */
    updateTime?: Date;
}

/**
 * Check if a given object implements the Org interface.
 */
export function instanceOfOrg(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "defaultTeamId" in value;
    isInstance = isInstance && "createTime" in value;

    return isInstance;
}

export function OrgFromJSON(json: any): Org {
    return OrgFromJSONTyped(json, false);
}

export function OrgFromJSONTyped(json: any, ignoreDiscriminator: boolean): Org {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'defaultTeamId': json['defaultTeamId'],
        'createTime': (new Date(json['createTime'])),
        'updateTime': !exists(json, 'updateTime') ? undefined : (new Date(json['updateTime'])),
    };
}

export function OrgToJSON(value?: Org | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'label': value.label,
        'defaultTeamId': value.defaultTeamId,
        'createTime': (value.createTime.toISOString()),
        'updateTime': value.updateTime === undefined ? undefined : (value.updateTime.toISOString()),
    };
}

