import ManageFoldersGraphic from 'assets/manage-folders-graphic.svg';

import { LinkButton } from 'components/LinkButton';

import classes from './style.module.css';

/**
 * Access denied page
 *
 */

export const ProjectNotFoundPage = () => (
  <div className="page">
    <div className={classes.content}>
      <h1 className={classes.title}>Project Not Found</h1>
      <ul className={classes.description}>
        <li>You do not have permission for this project or this project may not exists.</li>
        <li>Please ensure that you have the correct URL.</li>
      </ul>
      <img src={ManageFoldersGraphic} alt="manage folders" />
      <LinkButton.Secondary
        label="Go to app home"
        to="/"
      />
    </div>
  </div>
);
