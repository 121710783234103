import { SidebarDropdown } from 'components/SidebarDropdown';

import { SidebarLayout } from '../../SidebarLayout';

export const SidebarHeader = () => {
  return (
    <SidebarLayout.SidebarHeader>
      <SidebarDropdown />
    </SidebarLayout.SidebarHeader>
  );
};
