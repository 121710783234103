/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Org } from './Org';
import {
    OrgFromJSON,
    OrgFromJSONTyped,
    OrgToJSON,
} from './Org';

/**
 * 
 * @export
 * @interface BuilderInfoOrgRole
 */
export interface BuilderInfoOrgRole {
    /**
     * The role of the builder in the org.
     * @type {string}
     * @memberof BuilderInfoOrgRole
     */
    role: string;
    /**
     * The type of the principal.
     * @type {string}
     * @memberof BuilderInfoOrgRole
     */
    principalType: BuilderInfoOrgRolePrincipalTypeEnum;
    /**
     * The ID of the team or builder.
     * @type {string}
     * @memberof BuilderInfoOrgRole
     */
    principalId: string;
    /**
     * 
     * @type {Org}
     * @memberof BuilderInfoOrgRole
     */
    org: Org;
}


/**
 * @export
 */
export const BuilderInfoOrgRolePrincipalTypeEnum = {
    Team: 'team',
    Builder: 'builder'
} as const;
export type BuilderInfoOrgRolePrincipalTypeEnum = typeof BuilderInfoOrgRolePrincipalTypeEnum[keyof typeof BuilderInfoOrgRolePrincipalTypeEnum];


/**
 * Check if a given object implements the BuilderInfoOrgRole interface.
 */
export function instanceOfBuilderInfoOrgRole(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "principalType" in value;
    isInstance = isInstance && "principalId" in value;
    isInstance = isInstance && "org" in value;

    return isInstance;
}

export function BuilderInfoOrgRoleFromJSON(json: any): BuilderInfoOrgRole {
    return BuilderInfoOrgRoleFromJSONTyped(json, false);
}

export function BuilderInfoOrgRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuilderInfoOrgRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': json['role'],
        'principalType': json['principalType'],
        'principalId': json['principalId'],
        'org': OrgFromJSON(json['org']),
    };
}

export function BuilderInfoOrgRoleToJSON(value?: BuilderInfoOrgRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': value.role,
        'principalType': value.principalType,
        'principalId': value.principalId,
        'org': OrgToJSON(value.org),
    };
}

