/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateProjectRequestProject } from './UpdateProjectRequestProject';
import {
    UpdateProjectRequestProjectFromJSON,
    UpdateProjectRequestProjectFromJSONTyped,
    UpdateProjectRequestProjectToJSON,
} from './UpdateProjectRequestProject';

/**
 * 
 * @export
 * @interface UpdateProjectRequest
 */
export interface UpdateProjectRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProjectRequest
     */
    updateMask: Array<string>;
    /**
     * 
     * @type {UpdateProjectRequestProject}
     * @memberof UpdateProjectRequest
     */
    project: UpdateProjectRequestProject;
}

/**
 * Check if a given object implements the UpdateProjectRequest interface.
 */
export function instanceOfUpdateProjectRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "updateMask" in value;
    isInstance = isInstance && "project" in value;

    return isInstance;
}

export function UpdateProjectRequestFromJSON(json: any): UpdateProjectRequest {
    return UpdateProjectRequestFromJSONTyped(json, false);
}

export function UpdateProjectRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProjectRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updateMask': json['updateMask'],
        'project': UpdateProjectRequestProjectFromJSON(json['project']),
    };
}

export function UpdateProjectRequestToJSON(value?: UpdateProjectRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updateMask': value.updateMask,
        'project': UpdateProjectRequestProjectToJSON(value.project),
    };
}

