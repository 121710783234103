/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Consumer
 */
export interface Consumer {
    /**
     * The consumer reference.
     * @type {string}
     * @memberof Consumer
     */
    consumerRef: string;
    /**
     * The name of the consumer.
     * @type {string}
     * @memberof Consumer
     */
    consumerName: string;
    /**
     * The Ampersand project ID.
     * @type {string}
     * @memberof Consumer
     */
    projectId: string;
    /**
     * The time the consumer was created.
     * @type {Date}
     * @memberof Consumer
     */
    createTime: Date;
    /**
     * The time the consumer was last updated.
     * @type {Date}
     * @memberof Consumer
     */
    updateTime?: Date;
}

/**
 * Check if a given object implements the Consumer interface.
 */
export function instanceOfConsumer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "consumerRef" in value;
    isInstance = isInstance && "consumerName" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "createTime" in value;

    return isInstance;
}

export function ConsumerFromJSON(json: any): Consumer {
    return ConsumerFromJSONTyped(json, false);
}

export function ConsumerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Consumer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'consumerRef': json['consumerRef'],
        'consumerName': json['consumerName'],
        'projectId': json['projectId'],
        'createTime': (new Date(json['createTime'])),
        'updateTime': !exists(json, 'updateTime') ? undefined : (new Date(json['updateTime'])),
    };
}

export function ConsumerToJSON(value?: Consumer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'consumerRef': value.consumerRef,
        'consumerName': value.consumerName,
        'projectId': value.projectId,
        'createTime': (value.createTime.toISOString()),
        'updateTime': value.updateTime === undefined ? undefined : (value.updateTime.toISOString()),
    };
}

