import { BuilderInfo } from '@generated/api/src';
import CreateProjectIcon from 'assets/create-project.svg';
import ProjectLogoBlockIcon from 'assets/project-logo-block.svg';
import { ORG_OWNER_ROLE, useGetMyInfoQuery } from 'src/hooks/useGetMyInfoQuery';

import { useNavigate } from 'components/Nav';

import { SidebarDropdownButton } from './Button';

import classes from './style.module.css';

type ProjectListButtonsProps = {
  builderInfo: BuilderInfo | undefined;
  setOpen: (open: boolean) => void;
};

const ProjectListButtons = ({ builderInfo, setOpen }: ProjectListButtonsProps) => {
  const navigate = useNavigate();

  const projectRoles = builderInfo?.projectRoles || {};

  if (!Object.keys(projectRoles).length) {
    return <p className={classes.emptyProjectText}>No projects yet</p>;
  }

  return Object.values(projectRoles).map((projectMembership) => (
    <SidebarDropdownButton
      key={projectMembership.project.id}
      icon={ProjectLogoBlockIcon}
      title={projectMembership.project.name}
      onClick={() => {
        setOpen(false);
        navigate(`/projects/${projectMembership.project.id}`);
      }}
    />
  ));
};

type SwitchProjectSectionProps = {
  setOpen: (open: boolean) => void;
  builderInfo: BuilderInfo | undefined;
};

export const SwitchProjectSection = ({ setOpen, builderInfo }: SwitchProjectSectionProps) => {
  const navigate = useNavigate();
  const { data: myInfo } = useGetMyInfoQuery();

  const isOrgOwner = myInfo?.orgRole?.role === ORG_OWNER_ROLE;

  return (
    <div className={classes.switchProjects}>
      {isOrgOwner && (
        <SidebarDropdownButton
          icon={<img src={CreateProjectIcon} alt="create project" className={classes.createProjectIcon} />}
          title="Create project"
          onClick={() => {
            setOpen(false);
            navigate('/onboarding/create-project');
          }}
        />
      )}
      <ProjectListButtons builderInfo={builderInfo} setOpen={setOpen} />
    </div>
  );
};
