import ChevronDownIcon from 'assets/chevron-down.svg';
import { SidebarLayout } from 'src/layout/SidebarLayout/SidebarLayout';

import classes from './section.module.css';

type SidebarSectionProps = {
  children: React.ReactNode;
  title: string;
  alt: string;
  iconSrc: string;
};
export const SidebarSection = ({
  title, alt, iconSrc, children,
}: SidebarSectionProps) => (
  <details open className={classes.details}>
    <summary className={classes.sidebarSection}>
      <img src={iconSrc} alt={alt} />
      <SidebarLayout.SidebarMainLayout.Title title={title} />
      <img src={ChevronDownIcon} alt="down" className={classes.sidebarCollapseIcon} />
    </summary>
    <ul>
      {children}
    </ul>
  </details>
);
