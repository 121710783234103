import { useQuery } from '@tanstack/react-query';
import { useAPI } from 'data/api';

export const useGetMyInfoQuery = () => {
  const getAPI = useAPI();

  return useQuery({
    queryKey: ['myInfo'],
    queryFn: async () => {
      const api = await getAPI();
      return api.userApi.getMyInfo();
    },
  });
};

export const ORG_OWNER_ROLE = 'org:owner';
