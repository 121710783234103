/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiProblem,
  CreateDestinationRequest,
  Destination,
  InputValidationProblem,
  UpdateDestinationRequest,
} from '../models';
import {
    ApiProblemFromJSON,
    ApiProblemToJSON,
    CreateDestinationRequestFromJSON,
    CreateDestinationRequestToJSON,
    DestinationFromJSON,
    DestinationToJSON,
    InputValidationProblemFromJSON,
    InputValidationProblemToJSON,
    UpdateDestinationRequestFromJSON,
    UpdateDestinationRequestToJSON,
} from '../models';

export interface CreateDestinationOperationRequest {
    projectIdOrName: string;
    destination: CreateDestinationRequest;
}

export interface DeleteDestinationRequest {
    projectIdOrName: string;
    destinationId: string;
}

export interface GetDestinationRequest {
    projectIdOrName: string;
    destinationName: string;
}

export interface ListDestinationsRequest {
    projectIdOrName: string;
}

export interface UpdateDestinationOperationRequest {
    projectIdOrName: string;
    destinationId: string;
    destinationUpdate: UpdateDestinationRequest;
}

/**
 * DestinationApi - interface
 * 
 * @export
 * @interface DestinationApiInterface
 */
export interface DestinationApiInterface {
    /**
     * 
     * @summary Create a new destination
     * @param {string} projectIdOrName 
     * @param {CreateDestinationRequest} destination 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationApiInterface
     */
    createDestinationRaw(requestParameters: CreateDestinationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Destination>>;

    /**
     * Create a new destination
     */
    createDestination(requestParameters: CreateDestinationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Destination>;

    /**
     * 
     * @summary Delete a destination
     * @param {string} projectIdOrName 
     * @param {string} destinationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationApiInterface
     */
    deleteDestinationRaw(requestParameters: DeleteDestinationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete a destination
     */
    deleteDestination(requestParameters: DeleteDestinationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Get a destination
     * @param {string} projectIdOrName 
     * @param {string} destinationName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationApiInterface
     */
    getDestinationRaw(requestParameters: GetDestinationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Destination>>;

    /**
     * Get a destination
     */
    getDestination(requestParameters: GetDestinationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Destination>;

    /**
     * 
     * @summary List destinations
     * @param {string} projectIdOrName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationApiInterface
     */
    listDestinationsRaw(requestParameters: ListDestinationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Destination>>>;

    /**
     * List destinations
     */
    listDestinations(requestParameters: ListDestinationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Destination>>;

    /**
     * 
     * @summary Update a destination
     * @param {string} projectIdOrName 
     * @param {string} destinationId 
     * @param {UpdateDestinationRequest} destinationUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationApiInterface
     */
    updateDestinationRaw(requestParameters: UpdateDestinationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Destination>>;

    /**
     * Update a destination
     */
    updateDestination(requestParameters: UpdateDestinationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Destination>;

}

/**
 * 
 */
export class DestinationApi extends runtime.BaseAPI implements DestinationApiInterface {

    /**
     * Create a new destination
     */
    async createDestinationRaw(requestParameters: CreateDestinationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Destination>> {
        if (requestParameters.projectIdOrName === null || requestParameters.projectIdOrName === undefined) {
            throw new runtime.RequiredError('projectIdOrName','Required parameter requestParameters.projectIdOrName was null or undefined when calling createDestination.');
        }

        if (requestParameters.destination === null || requestParameters.destination === undefined) {
            throw new runtime.RequiredError('destination','Required parameter requestParameters.destination was null or undefined when calling createDestination.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Api-Key"] = this.configuration.apiKey("X-Api-Key"); // APIKeyHeader authentication
        }

        const response = await this.request({
            path: `/projects/{projectIdOrName}/destinations`.replace(`{${"projectIdOrName"}}`, encodeURIComponent(String(requestParameters.projectIdOrName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDestinationRequestToJSON(requestParameters.destination),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DestinationFromJSON(jsonValue));
    }

    /**
     * Create a new destination
     */
    async createDestination(requestParameters: CreateDestinationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Destination> {
        const response = await this.createDestinationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a destination
     */
    async deleteDestinationRaw(requestParameters: DeleteDestinationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectIdOrName === null || requestParameters.projectIdOrName === undefined) {
            throw new runtime.RequiredError('projectIdOrName','Required parameter requestParameters.projectIdOrName was null or undefined when calling deleteDestination.');
        }

        if (requestParameters.destinationId === null || requestParameters.destinationId === undefined) {
            throw new runtime.RequiredError('destinationId','Required parameter requestParameters.destinationId was null or undefined when calling deleteDestination.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Api-Key"] = this.configuration.apiKey("X-Api-Key"); // APIKeyHeader authentication
        }

        const response = await this.request({
            path: `/projects/{projectIdOrName}/destinations/{destinationId}`.replace(`{${"projectIdOrName"}}`, encodeURIComponent(String(requestParameters.projectIdOrName))).replace(`{${"destinationId"}}`, encodeURIComponent(String(requestParameters.destinationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a destination
     */
    async deleteDestination(requestParameters: DeleteDestinationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDestinationRaw(requestParameters, initOverrides);
    }

    /**
     * Get a destination
     */
    async getDestinationRaw(requestParameters: GetDestinationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Destination>> {
        if (requestParameters.projectIdOrName === null || requestParameters.projectIdOrName === undefined) {
            throw new runtime.RequiredError('projectIdOrName','Required parameter requestParameters.projectIdOrName was null or undefined when calling getDestination.');
        }

        if (requestParameters.destinationName === null || requestParameters.destinationName === undefined) {
            throw new runtime.RequiredError('destinationName','Required parameter requestParameters.destinationName was null or undefined when calling getDestination.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Api-Key"] = this.configuration.apiKey("X-Api-Key"); // APIKeyHeader authentication
        }

        const response = await this.request({
            path: `/projects/{projectIdOrName}/destinations/{destinationName}`.replace(`{${"projectIdOrName"}}`, encodeURIComponent(String(requestParameters.projectIdOrName))).replace(`{${"destinationName"}}`, encodeURIComponent(String(requestParameters.destinationName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DestinationFromJSON(jsonValue));
    }

    /**
     * Get a destination
     */
    async getDestination(requestParameters: GetDestinationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Destination> {
        const response = await this.getDestinationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List destinations
     */
    async listDestinationsRaw(requestParameters: ListDestinationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Destination>>> {
        if (requestParameters.projectIdOrName === null || requestParameters.projectIdOrName === undefined) {
            throw new runtime.RequiredError('projectIdOrName','Required parameter requestParameters.projectIdOrName was null or undefined when calling listDestinations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Api-Key"] = this.configuration.apiKey("X-Api-Key"); // APIKeyHeader authentication
        }

        const response = await this.request({
            path: `/projects/{projectIdOrName}/destinations`.replace(`{${"projectIdOrName"}}`, encodeURIComponent(String(requestParameters.projectIdOrName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DestinationFromJSON));
    }

    /**
     * List destinations
     */
    async listDestinations(requestParameters: ListDestinationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Destination>> {
        const response = await this.listDestinationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a destination
     */
    async updateDestinationRaw(requestParameters: UpdateDestinationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Destination>> {
        if (requestParameters.projectIdOrName === null || requestParameters.projectIdOrName === undefined) {
            throw new runtime.RequiredError('projectIdOrName','Required parameter requestParameters.projectIdOrName was null or undefined when calling updateDestination.');
        }

        if (requestParameters.destinationId === null || requestParameters.destinationId === undefined) {
            throw new runtime.RequiredError('destinationId','Required parameter requestParameters.destinationId was null or undefined when calling updateDestination.');
        }

        if (requestParameters.destinationUpdate === null || requestParameters.destinationUpdate === undefined) {
            throw new runtime.RequiredError('destinationUpdate','Required parameter requestParameters.destinationUpdate was null or undefined when calling updateDestination.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Api-Key"] = this.configuration.apiKey("X-Api-Key"); // APIKeyHeader authentication
        }

        const response = await this.request({
            path: `/projects/{projectIdOrName}/destinations/{destinationId}`.replace(`{${"projectIdOrName"}}`, encodeURIComponent(String(requestParameters.projectIdOrName))).replace(`{${"destinationId"}}`, encodeURIComponent(String(requestParameters.destinationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDestinationRequestToJSON(requestParameters.destinationUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DestinationFromJSON(jsonValue));
    }

    /**
     * Update a destination
     */
    async updateDestination(requestParameters: UpdateDestinationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Destination> {
        const response = await this.updateDestinationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
