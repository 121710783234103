/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IntegrationWriteObject } from './IntegrationWriteObject';
import {
    IntegrationWriteObjectFromJSON,
    IntegrationWriteObjectFromJSONTyped,
    IntegrationWriteObjectToJSON,
} from './IntegrationWriteObject';

/**
 * 
 * @export
 * @interface IntegrationWrite
 */
export interface IntegrationWrite {
    /**
     * 
     * @type {Array<IntegrationWriteObject>}
     * @memberof IntegrationWrite
     */
    objects?: Array<IntegrationWriteObject>;
}

/**
 * Check if a given object implements the IntegrationWrite interface.
 */
export function instanceOfIntegrationWrite(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IntegrationWriteFromJSON(json: any): IntegrationWrite {
    return IntegrationWriteFromJSONTyped(json, false);
}

export function IntegrationWriteFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationWrite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objects': !exists(json, 'objects') ? undefined : ((json['objects'] as Array<any>).map(IntegrationWriteObjectFromJSON)),
    };
}

export function IntegrationWriteToJSON(value?: IntegrationWrite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objects': value.objects === undefined ? undefined : ((value.objects as Array<any>).map(IntegrationWriteObjectToJSON)),
    };
}

