import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import TmpIntegrationIcon from 'assets/circle-heat-svgrepo-com.svg';
import IntegrationIcon from 'assets/integration-logo.svg';
import { Integration, ProviderInfo } from 'data/api';
import { useProviders } from 'src/hooks/useProviders';
import { capitalizeFirstLetter } from 'src/util';

import { useListIntegrations } from '../../../../hooks/useListIntegrations';
import { SidebarLayout } from '../../SidebarLayout';

import classes from './style.module.css';

type IntegrationNavSubsectionProps = {
  title: string;
  list: Integration[];
  projectId: string;
  iconSrc?: string;
};

type ProviderNavItems = Integration & { media: ProviderInfo['media'] };

const IntegrationNavSubsection = ({
  title, list, projectId, iconSrc,
}: IntegrationNavSubsectionProps) => (

  <details open>
    <summary className={classes.integrationNavSubSection}>
      <img height={24} width={24} src={iconSrc || TmpIntegrationIcon} alt={`${title}-icon`} />
      <SidebarLayout.SidebarMainLayout.Title title={`${title}`} />
    </summary>
    {list.map((integration) => (
      <SidebarLayout.SidebarMainLayout.ListNavLink
        key={integration.id}
        to={`/projects/${projectId}/integrations/${integration.id}`}
        label={integration.name}
      />
    ))}
  </details>
);

const IntegrationNavSubsectionContent = () => {
  const { projectId } = useParams();
  const { data: providers } = useProviders();
  const { data: integrations, isLoading, isError } = useListIntegrations();

  // This is a map of provider string to Integration[]
  const providerNavItems: Record<string, ProviderNavItems[]> = useMemo(() => (integrations || []).reduce(
    (acc: Record<string, ProviderNavItems[]>, integration) => {
      const providerDisplayName = providers?.[integration.provider]?.displayName
      || capitalizeFirstLetter(integration.provider);
      if (!acc[providerDisplayName]) acc[providerDisplayName] = [];
      acc[providerDisplayName].push({ ...integration, media: providers?.[integration.provider]?.media });
      return acc;
    },
    {},
  ), [integrations, providers]);

  const navItemKeys = useMemo(() => Object.keys(providerNavItems).sort(), [providerNavItems]);

  if (isLoading && !integrations) {
    return <p className={classes.noIntegrationText}>Loading...</p>;
  }

  if (isError) {
    return <p className={classes.noIntegrationText}>Failed to load integrations.</p>;
  }

  if (!navItemKeys.length) {
    return (
      <p className={classes.noIntegrationText}>
        <Link to={`/projects/${projectId}`}>
          No integrations found.
        </Link>
      </p>
    );
  }

  return (
    <>
      {navItemKeys.map((providerDisplayName) => (
        <IntegrationNavSubsection
          key={providerDisplayName}
          title={capitalizeFirstLetter(providerDisplayName)}
          list={providerNavItems[providerDisplayName]}
          projectId={projectId || ''}
          iconSrc={providerNavItems[providerDisplayName]?.[0].media?.regular?.iconURL}
        />
      ))}
    </>
  );
};

export const IntegrationsSection = () => {
  return (
    <SidebarLayout.SidebarMainLayout.SidebarSection title="Integrations" alt="integration" iconSrc={IntegrationIcon}>
      <IntegrationNavSubsectionContent />
    </SidebarLayout.SidebarMainLayout.SidebarSection>
  );
};
