// Protected Higher Level Pages
import { ProtectedPage } from 'src/layout/ProtectedPage/ProtectedPage';
import { SidebarProjectLayout } from 'src/layout/SidebarLayout/SidebarProjectLayout/SidebarProjectLayout';

import { ProjectProtectedPage } from '../ProjectProtectedPage/ProjectProtectedPage';

export const ProtectedPageWithProjectSidebar = (
  <ProtectedPage>
    <ProjectProtectedPage>
      <SidebarProjectLayout />
    </ProjectProtectedPage>
  </ProtectedPage>
);
