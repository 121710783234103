import { toast, ToastContainer, ToastOptions } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export const Toast = {
  toast,
  ToastContainer,
};

export const toastErrorOpt = (toastId: string, options?: ToastOptions) => {
  const base: ToastOptions = {
    position: 'top-right',
    toastId,
    autoClose: false,
  };

  return { ...base, ...options };
};
