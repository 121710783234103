/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AcceptInviteRequest,
  ApiProblem,
  BuilderInfo,
} from '../models';
import {
    AcceptInviteRequestFromJSON,
    AcceptInviteRequestToJSON,
    ApiProblemFromJSON,
    ApiProblemToJSON,
    BuilderInfoFromJSON,
    BuilderInfoToJSON,
} from '../models';

export interface AcceptInviteOperationRequest {
    acceptInviteRequest: AcceptInviteRequest;
}

/**
 * UserApi - interface
 * 
 * @export
 * @interface UserApiInterface
 */
export interface UserApiInterface {
    /**
     * 
     * @summary Accept an invite
     * @param {AcceptInviteRequest} acceptInviteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    acceptInviteRaw(requestParameters: AcceptInviteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BuilderInfo>>;

    /**
     * Accept an invite
     */
    acceptInvite(requestParameters: AcceptInviteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BuilderInfo>;

    /**
     * 
     * @summary Get information about the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getMyInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BuilderInfo>>;

    /**
     * Get information about the current user
     */
    getMyInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BuilderInfo>;

}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI implements UserApiInterface {

    /**
     * Accept an invite
     */
    async acceptInviteRaw(requestParameters: AcceptInviteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BuilderInfo>> {
        if (requestParameters.acceptInviteRequest === null || requestParameters.acceptInviteRequest === undefined) {
            throw new runtime.RequiredError('acceptInviteRequest','Required parameter requestParameters.acceptInviteRequest was null or undefined when calling acceptInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invites:accept`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcceptInviteRequestToJSON(requestParameters.acceptInviteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BuilderInfoFromJSON(jsonValue));
    }

    /**
     * Accept an invite
     */
    async acceptInvite(requestParameters: AcceptInviteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BuilderInfo> {
        const response = await this.acceptInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get information about the current user
     */
    async getMyInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BuilderInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/my-info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BuilderInfoFromJSON(jsonValue));
    }

    /**
     * Get information about the current user
     */
    async getMyInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BuilderInfo> {
        const response = await this.getMyInfoRaw(initOverrides);
        return await response.value();
    }

}
