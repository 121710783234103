/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiProblem,
  Builder,
  CreateOrgInviteRequest,
  CreateOrgRequest,
  InputValidationProblem,
  Invite,
  Org,
  UpdateOrgRequest,
} from '../models';
import {
    ApiProblemFromJSON,
    ApiProblemToJSON,
    BuilderFromJSON,
    BuilderToJSON,
    CreateOrgInviteRequestFromJSON,
    CreateOrgInviteRequestToJSON,
    CreateOrgRequestFromJSON,
    CreateOrgRequestToJSON,
    InputValidationProblemFromJSON,
    InputValidationProblemToJSON,
    InviteFromJSON,
    InviteToJSON,
    OrgFromJSON,
    OrgToJSON,
    UpdateOrgRequestFromJSON,
    UpdateOrgRequestToJSON,
} from '../models';

export interface CreateOrgOperationRequest {
    org: CreateOrgRequest;
}

export interface CreateOrgInviteOperationRequest {
    orgId: string;
    createOrgInviteRequest?: CreateOrgInviteRequest;
}

export interface DeleteOrgInviteRequest {
    orgId: string;
    inviteId: string;
}

export interface GetOrgRequest {
    orgId: string;
}

export interface GetOrgInviteRequest {
    orgId: string;
    inviteId: string;
}

export interface ListOrgBuildersRequest {
    orgId: string;
}

export interface ListOrgInvitesRequest {
    orgId: string;
}

export interface UpdateOrgOperationRequest {
    orgId: string;
    updateOrgRequest: UpdateOrgRequest;
}

/**
 * OrgApi - interface
 * 
 * @export
 * @interface OrgApiInterface
 */
export interface OrgApiInterface {
    /**
     * 
     * @summary Create a new organization
     * @param {CreateOrgRequest} org 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    createOrgRaw(requestParameters: CreateOrgOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Org>>;

    /**
     * Create a new organization
     */
    createOrg(requestParameters: CreateOrgOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Org>;

    /**
     * 
     * @summary Invite a user to an organization
     * @param {string} orgId 
     * @param {CreateOrgInviteRequest} [createOrgInviteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    createOrgInviteRaw(requestParameters: CreateOrgInviteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invite>>;

    /**
     * Invite a user to an organization
     */
    createOrgInvite(requestParameters: CreateOrgInviteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invite>;

    /**
     * 
     * @summary Revoke an invite
     * @param {string} orgId 
     * @param {string} inviteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    deleteOrgInviteRaw(requestParameters: DeleteOrgInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Revoke an invite
     */
    deleteOrgInvite(requestParameters: DeleteOrgInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Get an organization
     * @param {string} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    getOrgRaw(requestParameters: GetOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Org>>;

    /**
     * Get an organization
     */
    getOrg(requestParameters: GetOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Org>;

    /**
     * 
     * @summary Get an invite
     * @param {string} orgId 
     * @param {string} inviteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    getOrgInviteRaw(requestParameters: GetOrgInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invite>>;

    /**
     * Get an invite
     */
    getOrgInvite(requestParameters: GetOrgInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invite>;

    /**
     * 
     * @summary List builders for an organization
     * @param {string} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    listOrgBuildersRaw(requestParameters: ListOrgBuildersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Builder>>>;

    /**
     * List builders for an organization
     */
    listOrgBuilders(requestParameters: ListOrgBuildersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Builder>>;

    /**
     * 
     * @summary List invites for an organization
     * @param {string} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    listOrgInvitesRaw(requestParameters: ListOrgInvitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Invite>>>;

    /**
     * List invites for an organization
     */
    listOrgInvites(requestParameters: ListOrgInvitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Invite>>;

    /**
     * 
     * @summary Update an organization
     * @param {string} orgId 
     * @param {UpdateOrgRequest} updateOrgRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApiInterface
     */
    updateOrgRaw(requestParameters: UpdateOrgOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Org>>;

    /**
     * Update an organization
     */
    updateOrg(requestParameters: UpdateOrgOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Org>;

}

/**
 * 
 */
export class OrgApi extends runtime.BaseAPI implements OrgApiInterface {

    /**
     * Create a new organization
     */
    async createOrgRaw(requestParameters: CreateOrgOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Org>> {
        if (requestParameters.org === null || requestParameters.org === undefined) {
            throw new runtime.RequiredError('org','Required parameter requestParameters.org was null or undefined when calling createOrg.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Api-Key"] = this.configuration.apiKey("X-Api-Key"); // APIKeyHeader authentication
        }

        const response = await this.request({
            path: `/orgs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrgRequestToJSON(requestParameters.org),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgFromJSON(jsonValue));
    }

    /**
     * Create a new organization
     */
    async createOrg(requestParameters: CreateOrgOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Org> {
        const response = await this.createOrgRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Invite a user to an organization
     */
    async createOrgInviteRaw(requestParameters: CreateOrgInviteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invite>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling createOrgInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Api-Key"] = this.configuration.apiKey("X-Api-Key"); // APIKeyHeader authentication
        }

        const response = await this.request({
            path: `/orgs/{orgId}/invites`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrgInviteRequestToJSON(requestParameters.createOrgInviteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InviteFromJSON(jsonValue));
    }

    /**
     * Invite a user to an organization
     */
    async createOrgInvite(requestParameters: CreateOrgInviteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invite> {
        const response = await this.createOrgInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Revoke an invite
     */
    async deleteOrgInviteRaw(requestParameters: DeleteOrgInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling deleteOrgInvite.');
        }

        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling deleteOrgInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Api-Key"] = this.configuration.apiKey("X-Api-Key"); // APIKeyHeader authentication
        }

        const response = await this.request({
            path: `/orgs/{orgId}/invites/{inviteId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"inviteId"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Revoke an invite
     */
    async deleteOrgInvite(requestParameters: DeleteOrgInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOrgInviteRaw(requestParameters, initOverrides);
    }

    /**
     * Get an organization
     */
    async getOrgRaw(requestParameters: GetOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Org>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling getOrg.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Api-Key"] = this.configuration.apiKey("X-Api-Key"); // APIKeyHeader authentication
        }

        const response = await this.request({
            path: `/orgs/{orgId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgFromJSON(jsonValue));
    }

    /**
     * Get an organization
     */
    async getOrg(requestParameters: GetOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Org> {
        const response = await this.getOrgRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an invite
     */
    async getOrgInviteRaw(requestParameters: GetOrgInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invite>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling getOrgInvite.');
        }

        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling getOrgInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Api-Key"] = this.configuration.apiKey("X-Api-Key"); // APIKeyHeader authentication
        }

        const response = await this.request({
            path: `/orgs/{orgId}/invites/{inviteId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"inviteId"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InviteFromJSON(jsonValue));
    }

    /**
     * Get an invite
     */
    async getOrgInvite(requestParameters: GetOrgInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invite> {
        const response = await this.getOrgInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List builders for an organization
     */
    async listOrgBuildersRaw(requestParameters: ListOrgBuildersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Builder>>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling listOrgBuilders.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Api-Key"] = this.configuration.apiKey("X-Api-Key"); // APIKeyHeader authentication
        }

        const response = await this.request({
            path: `/orgs/{orgId}/builders`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BuilderFromJSON));
    }

    /**
     * List builders for an organization
     */
    async listOrgBuilders(requestParameters: ListOrgBuildersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Builder>> {
        const response = await this.listOrgBuildersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List invites for an organization
     */
    async listOrgInvitesRaw(requestParameters: ListOrgInvitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Invite>>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling listOrgInvites.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Api-Key"] = this.configuration.apiKey("X-Api-Key"); // APIKeyHeader authentication
        }

        const response = await this.request({
            path: `/orgs/{orgId}/invites`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InviteFromJSON));
    }

    /**
     * List invites for an organization
     */
    async listOrgInvites(requestParameters: ListOrgInvitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Invite>> {
        const response = await this.listOrgInvitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an organization
     */
    async updateOrgRaw(requestParameters: UpdateOrgOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Org>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling updateOrg.');
        }

        if (requestParameters.updateOrgRequest === null || requestParameters.updateOrgRequest === undefined) {
            throw new runtime.RequiredError('updateOrgRequest','Required parameter requestParameters.updateOrgRequest was null or undefined when calling updateOrg.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Api-Key"] = this.configuration.apiKey("X-Api-Key"); // APIKeyHeader authentication
        }

        const response = await this.request({
            path: `/orgs/{orgId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrgRequestToJSON(requestParameters.updateOrgRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgFromJSON(jsonValue));
    }

    /**
     * Update an organization
     */
    async updateOrg(requestParameters: UpdateOrgOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Org> {
        const response = await this.updateOrgRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
