import { Outlet } from 'react-router-dom';
import { SidebarLayout } from 'src/layout/SidebarLayout/SidebarLayout';

import { SidebarDropdown } from 'components/SidebarDropdown';

import { IntegrationsSection } from './IntegrationsSection/IntegrationsSection';
import { SettingsSection } from './SettingsSection';

const Sidebar = () => {
  return (
    <SidebarLayout.Sidebar>
      {/* Sidebar content goes here */}
      <SidebarLayout.SidebarHeader>
        <SidebarDropdown inProject />
      </SidebarLayout.SidebarHeader>
      <SidebarLayout.SidebarMain>
        <IntegrationsSection />
        <SettingsSection />
      </SidebarLayout.SidebarMain>
    </SidebarLayout.Sidebar>
  );
};

export const SidebarProjectLayout = () => (
  <SidebarLayout.LayoutContainer>
    <Sidebar />
    {/* Main page content goes here */}
    <SidebarLayout.MainPage>
      <Outlet />
    </SidebarLayout.MainPage>
  </SidebarLayout.LayoutContainer>
);
