import { FC } from 'react';
import LeisureGraphic from 'assets/leisure-graphic.svg';

import { FullPageCardLayout } from '../FullPageCardLayout';

type OnboardingTemplateProps = {
  children: React.ReactNode;
};

export const OnboardingTemplate: FC<OnboardingTemplateProps> = ({ children }) => (
  <FullPageCardLayout.PageLayout>
    <FullPageCardLayout.TwoColumnLayout>
      <FullPageCardLayout.PlaceCenterLayout>
        <img src={LeisureGraphic} alt="leisure" />
      </FullPageCardLayout.PlaceCenterLayout>
      <FullPageCardLayout.PlaceCenterLayout>
        {children}
      </FullPageCardLayout.PlaceCenterLayout>
    </FullPageCardLayout.TwoColumnLayout>
  </FullPageCardLayout.PageLayout>
);
