import { FC } from 'react';
import { AuthProvider } from 'auth/AuthProvider';

type AuthLayoutProps = {
  children: React.ReactNode;
};

export const AuthLayout: FC<AuthLayoutProps> = ({ children }) => (
  <AuthProvider>{children}</AuthProvider>
);
