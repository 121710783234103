/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateOrgRequestOrg } from './UpdateOrgRequestOrg';
import {
    UpdateOrgRequestOrgFromJSON,
    UpdateOrgRequestOrgFromJSONTyped,
    UpdateOrgRequestOrgToJSON,
} from './UpdateOrgRequestOrg';

/**
 * 
 * @export
 * @interface UpdateOrgRequest
 */
export interface UpdateOrgRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateOrgRequest
     */
    updateMask: Array<string>;
    /**
     * 
     * @type {UpdateOrgRequestOrg}
     * @memberof UpdateOrgRequest
     */
    org: UpdateOrgRequestOrg;
}

/**
 * Check if a given object implements the UpdateOrgRequest interface.
 */
export function instanceOfUpdateOrgRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "updateMask" in value;
    isInstance = isInstance && "org" in value;

    return isInstance;
}

export function UpdateOrgRequestFromJSON(json: any): UpdateOrgRequest {
    return UpdateOrgRequestFromJSONTyped(json, false);
}

export function UpdateOrgRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOrgRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updateMask': json['updateMask'],
        'org': UpdateOrgRequestOrgFromJSON(json['org']),
    };
}

export function UpdateOrgRequestToJSON(value?: UpdateOrgRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updateMask': value.updateMask,
        'org': UpdateOrgRequestOrgToJSON(value.org),
    };
}

