import classNames from 'classnames';

import { Loading } from 'components/Loading';

import classes from './loadingPage.module.css';

type LoadingPageProps = {
  className?: string;
  style?: React.CSSProperties;
};
export const LoadingPage = ({ className, style }: LoadingPageProps) => (
  <div
    className={className ? classNames(classes.loadingPage, className) : classes.loadingPage}
    style={style}
  >
    <Loading />
  </div>
);
