import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useAPI } from 'data/api';

export const useListIntegrations = () => {
  const getAPI = useAPI();
  const { projectId } = useParams();

  return useQuery({
    queryKey: ['listIntegrations', projectId],
    queryFn: async () => {
      const api = await getAPI();
      return api.integrationApi.listIntegrations({ projectIdOrName: projectId || '' });
    },
    enabled: !!projectId,
  });
};
