/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Config } from './Config';
import {
    ConfigFromJSON,
    ConfigFromJSONTyped,
    ConfigToJSON,
} from './Config';
import type { Connection } from './Connection';
import {
    ConnectionFromJSON,
    ConnectionFromJSONTyped,
    ConnectionToJSON,
} from './Connection';
import type { Group } from './Group';
import {
    GroupFromJSON,
    GroupFromJSONTyped,
    GroupToJSON,
} from './Group';

/**
 * 
 * @export
 * @interface Installation
 */
export interface Installation {
    /**
     * The installation ID.
     * @type {string}
     * @memberof Installation
     */
    id: string;
    /**
     * The Ampersand project ID.
     * @type {string}
     * @memberof Installation
     */
    projectId: string;
    /**
     * The integration ID.
     * @type {string}
     * @memberof Installation
     */
    integrationId: string;
    /**
     * 
     * @type {Group}
     * @memberof Installation
     */
    group?: Group;
    /**
     * The health status of the installation ("healthy", "unhealthy").
     * @type {string}
     * @memberof Installation
     */
    healthStatus: string;
    /**
     * 
     * @type {Connection}
     * @memberof Installation
     */
    connection: Connection;
    /**
     * The time the integration was first installed.
     * @type {Date}
     * @memberof Installation
     */
    createTime: Date;
    /**
     * The person who did the installation, in the format of "consumer:{consumer-id}".
     * @type {string}
     * @memberof Installation
     */
    createdBy: string;
    /**
     * The time the installation was last updated with a new config.
     * @type {Date}
     * @memberof Installation
     */
    updateTime?: Date;
    /**
     * 
     * @type {Config}
     * @memberof Installation
     */
    config: Config;
}

/**
 * Check if a given object implements the Installation interface.
 */
export function instanceOfInstallation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "projectId" in value;
    isInstance = isInstance && "integrationId" in value;
    isInstance = isInstance && "healthStatus" in value;
    isInstance = isInstance && "connection" in value;
    isInstance = isInstance && "createTime" in value;
    isInstance = isInstance && "createdBy" in value;
    isInstance = isInstance && "config" in value;

    return isInstance;
}

export function InstallationFromJSON(json: any): Installation {
    return InstallationFromJSONTyped(json, false);
}

export function InstallationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Installation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'projectId': json['projectId'],
        'integrationId': json['integrationId'],
        'group': !exists(json, 'group') ? undefined : GroupFromJSON(json['group']),
        'healthStatus': json['healthStatus'],
        'connection': ConnectionFromJSON(json['connection']),
        'createTime': (new Date(json['createTime'])),
        'createdBy': json['createdBy'],
        'updateTime': !exists(json, 'updateTime') ? undefined : (new Date(json['updateTime'])),
        'config': ConfigFromJSON(json['config']),
    };
}

export function InstallationToJSON(value?: Installation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projectId': value.projectId,
        'integrationId': value.integrationId,
        'group': GroupToJSON(value.group),
        'healthStatus': value.healthStatus,
        'connection': ConnectionToJSON(value.connection),
        'createTime': (value.createTime.toISOString()),
        'createdBy': value.createdBy,
        'updateTime': value.updateTime === undefined ? undefined : (value.updateTime.toISOString()),
        'config': ConfigToJSON(value.config),
    };
}

