import { useParams } from 'react-router-dom';
import SettingsIcon from 'assets/settings-logo.svg';

import { SidebarLayout } from '../SidebarLayout';

export const SettingsSection = () => {
  const { projectId } = useParams();
  return (
    <SidebarLayout.SidebarMainLayout.SidebarSection title="Project Settings" alt="settings" iconSrc={SettingsIcon}>
      <SidebarLayout.SidebarMainLayout.ListNavLink to={`/projects/${projectId}/settings`} label="General" />
      <SidebarLayout.SidebarMainLayout.ListNavLink to={`/projects/${projectId}/provider-apps`} label="Provider apps" />
      <SidebarLayout.SidebarMainLayout.ListNavLink to={`/projects/${projectId}/destinations`} label="Destinations" />
      <SidebarLayout.SidebarMainLayout.ListNavLink to={`/projects/${projectId}/api-keys`} label="API keys" />
    </SidebarLayout.SidebarMainLayout.SidebarSection>
  );
};
