import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  greenStatuses, redStatuses, StatusDetailEnum, StatusEnum,
} from 'data/enums';
/**
 * Throws error for a statement that should not be able to be executed.
 * https://tinytip.co/tips/ts-switch-assert-unreachable/
 * @param _value
 */
export const assertUnreachable = (_value: any): never => {
  throw new Error(`Statement should be unreachable: '${_value}'`);
};

/**
 *
 * @param query
 * @param terms
 * @returns true if any of the terms includes the query provided
 */
export function containsQuery(query: string, ...terms: string[]): boolean {
  return terms.some((term) => term.toLowerCase().includes(query.toLowerCase()));
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useLocationQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 *
 * @param email string
 * @returns domain from email or null if not found
 */
export function getDomainFromEmail(email: string): string | null {
  const regex = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  const match = email.match(regex);

  if (match && match[1]) {
    return match[1];
  }
  return null;
}

/**
 * categorizeStatus turns a detailed status into a StatusEnum.
 * @param status string that parses into a StatusDetailEnum
 * @returns StatusEnum
 */
export function categorizeStatus(status: string): StatusEnum {
  const StatusDetail = StatusDetailEnum[status as keyof typeof StatusDetailEnum];
  if (greenStatuses.has(StatusDetail)) {
    return StatusEnum.HEALTHY;
  }
  if (redStatuses.has(StatusDetail)) {
    return StatusEnum.UNHEALTHY;
  }

  // If we haven't seen this status before, we assume it's unknown.
  return StatusEnum.UNKNOWN;
}

/**
 * formatDuration takes a duration in milliseconds and returns a string
 * representing the duration in milliseconds, seconds or minutes.
 * @param durationMS number
 * @returns string
 */
export function formatDuration(durationMS: number): string {
  if (durationMS < 1000) {
    return `${durationMS}ms`;
  }
  if (durationMS < 60000) {
    return `${Math.floor(durationMS / 1000)}s`;
  }
  return `${Math.floor(durationMS / 60000)} min`;
}

/**
 * toTitleCase takes a string and returns it in title case, i.e. it capitalizes the first
 * letter of each word. A word is defined as a sequence of characters separated by a space,
 * hyphen, or an underscore.
 * For example, all the following strings will be converted to "Hello World":
 * "hello world", "hello-world", "hello_world" and "HELLO WORLD"
 * @param str string
 * @returns string
 */
export function toTitleCase(str: string): string {
  return str
    .toLowerCase()
    .split(/[\s-_]+/) // Split on space, hyphen, and underscore
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' '); // Join with a space to ensure title case
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const VALIDATION_REGEX = {
  alphaNumericAndSpaces: /^[\w\-\s]+$/,
  alphaNumericAndDashes: /^[a-zA-Z0-9-]+$/,
  alphaNumericAndSpacesDashesPeriods: /^[a-zA-Z0-9\s.-]+$/,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  url: /^(https):\/\/[^ "]+$/,
};

export const urlPathWithSearchParams = (path: string, searchParams?: string) => {
  return path + (searchParams ? `?${searchParams}` : '');
};
