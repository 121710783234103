import { LazyWithSuspense } from '../LazyWithSuspense';

// Usage with dynamic imports
const IntegrationPageLazy = LazyWithSuspense(() => import('src/views/project/IntegrationsPage')
  .then((module) => ({ default: module.IntegrationsPage })));

const InstallationsPageLazy = LazyWithSuspense(() => import('src/views/project/InstallationsPage')
  .then((module) => ({ default: module.InstallationsPage })));

const OperationsPageLazy = LazyWithSuspense(() => import('src/views/project/OperationsPage')
  .then((module) => ({ default: module.OperationsPage })));

const OperationLogsPageLazy = LazyWithSuspense(() => import('src/views/project/OperationLogsPage/OperationLogsPage')
  .then((module) => ({ default: module.OperationLogsPage })));

const GeneralSettingsPageLazy = LazyWithSuspense(() => import('src/views/project/Settings/GeneralSettingsPage')
  .then((module) => ({ default: module.GeneralSettingsPage })));

const ApiKeysPageLazy = LazyWithSuspense(() => import('src/views/project/Settings/ApiKeysPage')
  .then((module) => ({ default: module.ApiKeysPage })));

const ProviderAppsPageLazy = LazyWithSuspense(() => import('src/views/project/Settings/ProviderAppsPage')
  .then((module) => ({ default: module.ProviderAppsPage })));

const DestinationsPageLazy = LazyWithSuspense(() => import('src/views/project/Settings/DestinationsPage')
  .then((module) => ({ default: module.DestinationsPage })));

export {
  ApiKeysPageLazy,
  DestinationsPageLazy,
  IntegrationPageLazy,
  InstallationsPageLazy,
  GeneralSettingsPageLazy,
  OperationsPageLazy,
  OperationLogsPageLazy,
  ProviderAppsPageLazy,
};
