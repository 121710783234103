import { ApiProblem } from '@generated/api/src';
import { QueryClientConfig } from '@tanstack/react-query';

import { Toast, toastErrorOpt } from 'components/Toast';

import classes from './style.module.css';

export interface MutationPayload<T> {
  data: T;
  toastId?: string;
}

const ToastError = (error: string, toastId: string) => {
  Toast.toast.error(
    error,
    toastErrorOpt(`${toastId}-error`, { className: classes.toast }),
  );
};

export const defaultOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      // prevent refetching on window focus/tab switch.
      // (may cause forms to reset when user switches tabs)
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: (error: any, variable: any, context) => {
        // typecast is needed because onError requires `variable` to be of type unknown
        // but we are passing it as MutationPayload<T> in the mutation call
        // T are the actual data passed to `data` field in the mutation call
        // Since the generic type is unknown, we need to typecast it to MutationPayload<any>
        const payload = variable as MutationPayload<any>;
        console.error('Global callback error:', error, variable, context);
        if (payload?.toastId) {
          if (error?.response) {
            error.response.json().then((problem: ApiProblem) => {
              const message = `${problem.detail ?? problem.title ?? 'Unknown error'}${problem.remedy
                ? `\n${problem.remedy}` : ''}`;
              ToastError(message, payload?.toastId || 'unknown'); // payload.toastId is always defined
            });
          } else { // this should not happen but just in case
            console.error('err:', error);
          }
        } else {
          console.error('err:', error);
        }
      },
    },
  },
};
