import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SignUp } from 'auth/index';
import { OnboardingTemplate } from 'src/layout/OnboardingTemplate';
import { homePath, newUserPath, signInPath } from 'src/routes/constants';
import { urlPathWithSearchParams } from 'src/util';

export const SignUpPage: FC = () => {
  const [searchParams] = useSearchParams();
  const searchParamString = searchParams.toString();

  return (
    <OnboardingTemplate>
      <SignUp
        signInUrl={urlPathWithSearchParams(signInPath, searchParamString)}
        forceRedirectUrl={urlPathWithSearchParams(newUserPath, searchParamString)}
        signInForceRedirectUrl={urlPathWithSearchParams(homePath, searchParamString)}
      />
    </OnboardingTemplate>
  );
};
