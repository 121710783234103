import { FC, ReactNode } from 'react';
import { ClerkProvider } from '@clerk/clerk-react';

const CLERK_PUB_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
if (!CLERK_PUB_KEY) {
  throw new Error('Missing Clerk Publishable Key');
}

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => (
  <ClerkProvider publishableKey={CLERK_PUB_KEY}>{children}</ClerkProvider>
);
