import ManageFoldersGraphic from 'assets/manage-folders-graphic.svg';

import { LinkButton } from 'components/LinkButton';

import classes from './style.module.css';

/**
 * No Match - 404 page
 *
 */

export const NoMatchPage = (
  <div className="page">
    <div className={classes.content}>
      <h1 className={classes.title}>404</h1>
      <p className={classes.description}>The URL you entered is invalid.</p>
      <img src={ManageFoldersGraphic} alt="manage folders" />
      <LinkButton.Secondary
        label="Go to app home"
        to="/"
      />
    </div>
  </div>
);
