import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { Dialog } from 'components/Dialog/Dialog';

import classes from './overFlowCell.module.css';

type OverFlowAction = {
  name: string;
  action: () => void;
  variant?: 'primary' | 'danger';
  hasDialog?: boolean; // will open a dialog for the action if true
  dialogVariant?: 'danger' | 'default';
  dialogConfirmText?: string;
  dialogChildren?: React.ReactNode;
};

type OverFlowButtonProps = {
  actions: OverFlowAction[];
};

export const OverFlowCell = ({ actions }: OverFlowButtonProps) => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [dialogAction, setDialogAction] = useState<OverFlowAction | null>(null);
  const toggleMenu = () => setOpenMenu(!openMenu);

  // close the menu when clicking outside of it
  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    if (openMenu !== false) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openMenu]);

  return (
    <>
      <div className={classes.overflowContainer}>
        <button
          type="button"
          className={classes.overflowButton}
          onClick={() => toggleMenu()}
        >
          ⋮
        </button>
        {openMenu && (
        <div ref={menuRef} className={classes.overflowMenu}>
          {actions.map((action) => (
            <button
              type="button"
              key={action.name}
              className={classNames(classes.overflowMenuItem, { [classes.danger]: action.variant === 'danger' })}
              onClick={action.hasDialog ? () => setDialogAction(action) : action.action}
            >
              {action.name}
            </button>
          ))}
        </div>
        )}
      </div>
      { dialogAction && (
      <Dialog
        headerText={dialogAction.name}
        onConfirm={() => {
          dialogAction.action();
          setDialogAction(null);
        }}
        onCancel={() => setDialogAction(null)}
        confirmText={dialogAction.dialogConfirmText}
        cancelText="Cancel"
        variant={dialogAction.dialogVariant}
      >
        {dialogAction?.dialogChildren}
      </Dialog>
      )}
    </>
  );
};
