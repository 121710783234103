import { LazyWithSuspense } from '../LazyWithSuspense';

// Home Pages - dynamic imports
const MyProjectsPageLazy = LazyWithSuspense(() => import('src/views/home/MyProjectsPage/MyProjectsPage')
  .then((module) => ({ default: module.MyProjectsPage })));

const OrgSettingsPageLazy = LazyWithSuspense(() => import('src/views/home/Organization/General/OrgSettingsPage')
  .then((module) => ({ default: module.OrgSettingsPage })));

const OrgUsersPageLazy = LazyWithSuspense(() => import('src/views/home/Organization/Users/OrgUsersPage')
  .then((module) => ({ default: module.OrgUsersPage })));

// Onboarding Pages - dynamic imports
const CreateOrgPageLazy = LazyWithSuspense(() => import('src/views/onboarding/CreateOrgPage/CreateOrgPage')
  .then((module) => ({ default: module.CreateOrgPage })));

const CreateProjectPageLazy = LazyWithSuspense(() => import('src/views/onboarding/CreateProjectPage/CreateProjectPage')
  .then((module) => ({ default: module.CreateProjectPage })));

export {
  CreateOrgPageLazy,
  CreateProjectPageLazy,
  MyProjectsPageLazy,
  OrgSettingsPageLazy,
  OrgUsersPageLazy,
};
