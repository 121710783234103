import { NavLink } from 'components/Nav';

import classes from './style.module.css';

const navLinkClassName = ({ isActive }: { isActive: boolean; }) => {
  return `${classes.sectionListItem} ${isActive ? classes.sectionListItemActive : ''}`;
};

type SectionListNavLinkProps = {
  to: string;
  label: string;
};

/**
 * sidebar section nav link that must go inside a list <ul>
 * @param param0
 * @returns
 */
export const SectionListNavLink = ({ to, label }: SectionListNavLinkProps) => (
  <NavLink to={to} className={navLinkClassName}>
    <li key={label}>{label}</li>
  </NavLink>
);
