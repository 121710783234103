import React, { useEffect } from 'react';
import CloseIcon from 'assets/close-icon.svg';

import classes from './style.module.css';

interface DialogProps {
  headerText: string;
  cancelText?: string;
  confirmText?: string;
  onCancel?: ()=>void;
  onConfirm?: ()=>void;
  variant?: 'danger' | 'default' ;
  children?: React.ReactNode;
  hideButtons?: boolean;
}

/**
 * Generic dialog component that can be used to display a dialog with a header, body, and two buttons
 * @param
 * @returns ConfirmDialog component
 */
export const Dialog: React.FC<DialogProps> = ({
  headerText, cancelText = 'Cancel', confirmText = 'Confirm', onCancel, onConfirm, variant = 'default', children,
  hideButtons = false,
}) => {
  const confirmButtonClass = variant === 'danger' ? classes.confirmDangerButton : classes.confirmButton;

  useEffect(() => {
    // Prevent scrolling when the dialog is open
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  return (
    <div
      className={classes.background}
      onClick={(e) => {
        // If the user clicks outside the dialog, close it
        if (e.target === e.currentTarget) { onCancel?.(); }
      }}
      role="presentation"
    >
      <div className={classes.popup}>
        <button className={classes.closeButton} type="button" onClick={onCancel}>
          <img className={classes.closeIcon} src={CloseIcon} alt="Close icon" />
        </button>
        <p className={classes.headerText}>{headerText}</p>
        {children}
        {!hideButtons && (
        <div className={classes.buttonWrapper}>
          <button type="button" className={classes.cancelButton} onClick={onCancel}>{cancelText}</button>
          <button type="button" className={confirmButtonClass} onClick={onConfirm}>{confirmText}</button>
        </div>
        )}
      </div>
    </div>
  );
};
