/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateInstallationRequestInstallationConfig } from './UpdateInstallationRequestInstallationConfig';
import {
    UpdateInstallationRequestInstallationConfigFromJSON,
    UpdateInstallationRequestInstallationConfigFromJSONTyped,
    UpdateInstallationRequestInstallationConfigToJSON,
} from './UpdateInstallationRequestInstallationConfig';

/**
 * The installation fields to update. (Only include the fields you'd like to update.)
 * @export
 * @interface UpdateInstallationRequestInstallation
 */
export interface UpdateInstallationRequestInstallation {
    /**
     * The ID of the SaaS connection tied to this installation.
     * @type {string}
     * @memberof UpdateInstallationRequestInstallation
     */
    connectionId?: string;
    /**
     * 
     * @type {UpdateInstallationRequestInstallationConfig}
     * @memberof UpdateInstallationRequestInstallation
     */
    config?: UpdateInstallationRequestInstallationConfig;
}

/**
 * Check if a given object implements the UpdateInstallationRequestInstallation interface.
 */
export function instanceOfUpdateInstallationRequestInstallation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateInstallationRequestInstallationFromJSON(json: any): UpdateInstallationRequestInstallation {
    return UpdateInstallationRequestInstallationFromJSONTyped(json, false);
}

export function UpdateInstallationRequestInstallationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateInstallationRequestInstallation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connectionId': !exists(json, 'connectionId') ? undefined : json['connectionId'],
        'config': !exists(json, 'config') ? undefined : UpdateInstallationRequestInstallationConfigFromJSON(json['config']),
    };
}

export function UpdateInstallationRequestInstallationToJSON(value?: UpdateInstallationRequestInstallation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connectionId': value.connectionId,
        'config': UpdateInstallationRequestInstallationConfigToJSON(value.config),
    };
}

