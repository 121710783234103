/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DefaultPeriod } from './DefaultPeriod';
import {
    DefaultPeriodFromJSON,
    DefaultPeriodFromJSONTyped,
    DefaultPeriodToJSON,
} from './DefaultPeriod';

/**
 * 
 * @export
 * @interface Backfill
 */
export interface Backfill {
    /**
     * 
     * @type {DefaultPeriod}
     * @memberof Backfill
     */
    defaultPeriod: DefaultPeriod;
}

/**
 * Check if a given object implements the Backfill interface.
 */
export function instanceOfBackfill(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "defaultPeriod" in value;

    return isInstance;
}

export function BackfillFromJSON(json: any): Backfill {
    return BackfillFromJSONTyped(json, false);
}

export function BackfillFromJSONTyped(json: any, ignoreDiscriminator: boolean): Backfill {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defaultPeriod': DefaultPeriodFromJSON(json['defaultPeriod']),
    };
}

export function BackfillToJSON(value?: Backfill | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defaultPeriod': DefaultPeriodToJSON(value.defaultPeriod),
    };
}

