import { FC } from 'react';

import { AmpersandLogo } from 'components/AmpersandLogo';

import classes from './style.module.css';

type PlaceCenterLayoutProps = {
  children: React.ReactNode;
};

const PlaceCenterLayout: FC<PlaceCenterLayoutProps> = ({ children }) => (
  <div className={classes.placeCenterContainer}>
    {children}
  </div>
);

type TwoColumnLayoutProps = {
  children: React.ReactNode;
};

const TwoColumnLayout: FC<TwoColumnLayoutProps> = ({ children }) => (
  <div className={classes.twoColumnGrid}>
    {children}
  </div>
);

type FullPageCardProps = {
  children: React.ReactNode;
};

/**
 * A layout that renders a full page card with a logo and content passed as children.
 */
const PageLayout: FC<FullPageCardProps> = ({ children }) => (
  <div className={classes.page}>
    <div className={classes.logoContainer}><AmpersandLogo /></div>
    <main className={classes.card}>
      {children}
    </main>
  </div>
);

type TitleProps = {
  children: React.ReactNode;
};

const Title = ({ children }: TitleProps) => (
  <h1 className={classes.title}>{children}</h1>
);

const Main = ({ children }: FullPageCardProps) => (
  <main className={classes.main}>
    {children}
  </main>
);

export const FullPageCardLayout = {
  Title,
  Main,
  PageLayout,
  TwoColumnLayout,
  PlaceCenterLayout,
};
