import { useEffect } from 'react';
import ChevronDownIcon from 'assets/chevron-down.svg';
import ProjectLogoBlockIcon from 'assets/project-logo-block.svg';
import { BuilderInfo } from 'data/api';

import contentClasses from '../DropdownContent/style.module.css';
import classes from './style.module.css';

type DropdownButtonProps = {
  setOpen: (open: boolean) => void;
  toggleOpen: () => void;
  inProject: boolean | undefined;
  projectName: string;
  builderInfo: BuilderInfo | undefined;
};

export const DropdownButton = ({
  setOpen, toggleOpen, inProject, projectName, builderInfo,
}: DropdownButtonProps) => {
  useEffect(() => {
    // If the user clicks outside the dropdown, close it
    const handleClick = (e: MouseEvent) => {
      if (!e.target) return;
      const target = e.target as HTMLElement;

      const isElementOutsideDropdown = target.closest(`.${classes.dropdownButton}`) === null
        && target.closest(`.${contentClasses.dropdownContent}`) === null;

      if (isElementOutsideDropdown) setOpen(false);
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [setOpen]);

  return (
    <button
      type="button"
      className={classes.dropdownButton}
      onClick={toggleOpen}
    >
      <img src={ProjectLogoBlockIcon} alt="project icon" className={classes.dropdownProjectIcon} />
      <p>{inProject ? projectName : builderInfo?.orgRole?.org?.label}</p>
      <img src={ChevronDownIcon} alt="down" className={classes.dropdownButtonArrow} />
    </button>
  );
};
