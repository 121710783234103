/* tslint:disable */
/* eslint-disable */
/**
 * Ampersand public API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImportConnectionRequest } from './ImportConnectionRequest';
import {
    ImportConnectionRequestFromJSON,
    ImportConnectionRequestFromJSONTyped,
    ImportConnectionRequestToJSON,
} from './ImportConnectionRequest';
import type { ImportInstallationRequestConfig } from './ImportInstallationRequestConfig';
import {
    ImportInstallationRequestConfigFromJSON,
    ImportInstallationRequestConfigFromJSONTyped,
    ImportInstallationRequestConfigToJSON,
} from './ImportInstallationRequestConfig';

/**
 * 
 * @export
 * @interface ImportInstallationRequest
 */
export interface ImportInstallationRequest {
    /**
     * The SaaS provider that this installation connects to.
     * @type {string}
     * @memberof ImportInstallationRequest
     */
    provider: string;
    /**
     * 
     * @type {ImportConnectionRequest}
     * @memberof ImportInstallationRequest
     */
    connection: ImportConnectionRequest;
    /**
     * 
     * @type {ImportInstallationRequestConfig}
     * @memberof ImportInstallationRequest
     */
    config: ImportInstallationRequestConfig;
}

/**
 * Check if a given object implements the ImportInstallationRequest interface.
 */
export function instanceOfImportInstallationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "provider" in value;
    isInstance = isInstance && "connection" in value;
    isInstance = isInstance && "config" in value;

    return isInstance;
}

export function ImportInstallationRequestFromJSON(json: any): ImportInstallationRequest {
    return ImportInstallationRequestFromJSONTyped(json, false);
}

export function ImportInstallationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportInstallationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': json['provider'],
        'connection': ImportConnectionRequestFromJSON(json['connection']),
        'config': ImportInstallationRequestConfigFromJSON(json['config']),
    };
}

export function ImportInstallationRequestToJSON(value?: ImportInstallationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': value.provider,
        'connection': ImportConnectionRequestToJSON(value.connection),
        'config': ImportInstallationRequestConfigToJSON(value.config),
    };
}

