import React, {
  ComponentType, lazy, ReactNode, Suspense,
} from 'react';

import { DelayedLoadingPage } from './DelayedFallback';

// A generic function that takes a dynamic import promise and optionally a fallback component
export function LazyWithSuspense<T extends ComponentType<any>>(
  importFunc: () => Promise<{ default: T; }>,
  fallback: ReactNode = <DelayedLoadingPage />,
) {
  const LazyComponent = lazy(importFunc);
  return (props: React.ComponentProps<T>) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
}
