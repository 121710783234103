import { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetMyInfoQuery } from 'src/hooks/useGetMyInfoQuery';

import { DropdownButton } from './DropdownButton/DropdownButton';
import { DropdownContent } from './DropdownContent';
import { UserProfileModal } from './UserProfileModal';

type SidebarDropdownProps = {
  inProject?: boolean;
};

export const SidebarDropdown: FC<SidebarDropdownProps> = ({ inProject }) => {
  const [open, setOpen] = useState(false);
  const [userProfileOpen, setUserProfileOpen] = useState(false);

  const toggleOpen = useCallback(() => setOpen(!open), [open]);

  const { projectId } = useParams();
  const { data: builderInfo } = useGetMyInfoQuery();
  const projectName = (projectId && builderInfo?.projectRoles?.[projectId]?.project?.name) || 'Missing project name';

  return (
    <>
      <DropdownButton
        setOpen={setOpen}
        toggleOpen={toggleOpen}
        inProject={inProject}
        projectName={projectName}
        builderInfo={builderInfo}
      />
      {open && (
        <DropdownContent
          inProject={inProject}
          setOpen={setOpen}
          setUserProfileOpen={setUserProfileOpen}
        />
      )}
      {userProfileOpen && <UserProfileModal setUserProfileOpen={setUserProfileOpen} />}
    </>
  );
};
